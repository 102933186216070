class AccessControl {

    constructor() {
        this.accessConfig = {
            'login.atcsadvogados.com.br': {
                'cobranca': {
                    'cobranca_extrajudicial': true,
                    'cobranca_amigavel': false,
                    'editar_custo_cobranca': true,
                    'condominio_unidade': true,
                    'editar_processo': true
                },
                'acordos': true,
                'cobranca_rapida_honorarios': true
            },
            'login.cartoriopay.com': { //Atualmente Cartorio Pay e Imovel Pay
                'cobranca': {
                    'cobranca_extrajudicial': false,
                    'cobranca_amigavel': true,
                    'editar_custo_cobranca': false,
                    'condominio_unidade': false,
                    'editar_processo': false
                },
                'acordos': false,
                'cobranca_rapida_honorarios': false
            },
            'login.imovelpay.com.br': { //Atualmente Cartorio Pay e Imovel Pay
                'cobranca': {
                    'cobranca_extrajudicial': false,
                    'cobranca_amigavel': true,
                    'editar_custo_cobranca': false,
                    'condominio_unidade': true,
                    'editar_processo': false
                },
                'acordos': false,
                'cobranca_rapida_honorarios': false
            },
            'login.mgex.com.br': { //Atualmente Cartorio Pay e Imovel Pay
                'cobranca': {
                    'cobranca_extrajudicial': false,
                    'cobranca_amigavel': true,
                    'editar_custo_cobranca': false,
                    'condominio_unidade': false,
                    'editar_processo': false
                },
                'acordos': false,
                'cobranca_rapida_honorarios': false
            },
        };
    }

    buscaAcesso() {
        const domainUrl = localStorage.getItem('portalDomain').replace('https://', ''); //inicializado no arquivo marca.js
        return this.accessConfig[domainUrl] || this.accessConfig['default'];
    }
}

const accessControl = new AccessControl();

export default () => accessControl.buscaAcesso();
