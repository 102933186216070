import axios from './axiosInstance';
import { getBearerTokenConfig } from './auth';

async function buscaCobrancas(hashId, datefrom = null, dateto = null) {
    const endpoint = localStorage.getItem('portalDomain') !== null ?
        localStorage.getItem('portalDomain') + '/api/v2' : process.env.REACT_APP_API_EZCALE;

    let buscaCobrancasData = {
        datefrom,
        dateto,
        action: 'invoiceslist'
    }
    if (hashId !== 'all') {
        buscaCobrancasData.hashInvoiceGroupId = hashId
    }
    else {
        buscaCobrancasData.action = 'invoiceslist25';
    }
    if (datefrom == null) {
        buscaCobrancasData = {
            ...buscaCobrancasData,
            datefrom: '2023-01-01'
        }
    }
    if (dateto == null) {
        buscaCobrancasData = {
            ...buscaCobrancasData,
            dateto: '2030-10-15'
        }
    }

    try {
        const { data } = await axios.post(endpoint + "/invoices.php",
            buscaCobrancasData,
            getBearerTokenConfig());
        if (data.status_code === 200) {
            return data.data;
        }
        return [];
    }
    catch {
        return [];
    }
}

async function getDetalhesDaCobranca(hashInvoiceId) {

    try {
        const endpoint = localStorage.getItem('portalDomain') !== null ?
            localStorage.getItem('portalDomain') + '/api/v2' : process.env.REACT_APP_API_EZCALE;

        const config = getBearerTokenConfig();
        config.params = {
            action: "invoicedetails",
            hashInvoiceId
        }
        const { data } = await axios.get(endpoint + "/invoices.php", config);
        if (data.status_code === 200) {
            return data.data;
        }
        return [];
    }
    catch {
        return {};
    }
}

async function criaCobranca(cobrancaData, hashCarteira = null) {
    const endpoint = localStorage.getItem('portalDomain') !== null ?
        localStorage.getItem('portalDomain') + '/api/v2' : process.env.REACT_APP_API_EZCALE;

    if (cobrancaData.tipo_cobranca == undefined ||
        cobrancaData.tipo_cobranca == null ||
        cobrancaData.tipo_cobranca == ''
    ) {
        cobrancaData.tipo_cobranca = 'cobranca_extrajudicial';
    }
    const criaCobrancaData = {
        action: "createinvoice",
        payload: [cobrancaData]
    };
    if (hashCarteira !== null) {
        criaCobrancaData['invoiceGroup'] = hashCarteira;
    }
    const { data } = await axios.post(endpoint + "/invoices.php",
        criaCobrancaData,
        getBearerTokenConfig());
    if (data.status_code === 200) {
        return data.data;
    }
    return [];

}

async function darBaixaEmCobranca(arrayDeCobrancas, motivo) {
    const endpoint = localStorage.getItem('portalDomain') !== null ?
        localStorage.getItem('portalDomain') + '/api/v2' : process.env.REACT_APP_API_EZCALE;

    const criaCobrancaData = {
        "action": "invoicemanualclearance",
        "hashInvoiceArray": arrayDeCobrancas,
        "clearanceDetail": motivo
    };
    const { data } = await axios.post(endpoint + "/invoices.php",
        criaCobrancaData,
        getBearerTokenConfig());
    if (data.status_code === 200) {
        return data.data;
    }
    return [];

}

async function emiteCobrancas(arrayDeCobrancas) {
    const endpoint = localStorage.getItem('portalDomain') !== null ?
        localStorage.getItem('portalDomain') + '/api/v2' : process.env.REACT_APP_API_EZCALE;
    const criaCobrancaData = {
        "action": "invoiceissuebankslip",
        "hashInvoiceArray": arrayDeCobrancas
    };
    const { data } = await axios.post(endpoint + "/invoices.php",
        criaCobrancaData,
        getBearerTokenConfig());
    if (data.status_code === 200) {
        return data.data;
    }
    return [];

}

async function moveCobrancas(arrayDeCobrancas, carteiraDeDestino) {
    const endpoint = localStorage.getItem('portalDomain') !== null ?
        localStorage.getItem('portalDomain') + '/api/v2' : process.env.REACT_APP_API_EZCALE;
    const criaCobrancaData = {
        "action": "moveinvoice",
        "hashInvoiceArray": arrayDeCobrancas,
        "hashInvoiceGroupId": carteiraDeDestino
    };
    const { data } = await axios.post(endpoint + "/invoices.php",
        criaCobrancaData,
        getBearerTokenConfig());
    if (data.status_code === 200) {
        return data.data;
    }
    return [];

}

export {
    buscaCobrancas,
    getDetalhesDaCobranca,
    criaCobranca,
    darBaixaEmCobranca,
    emiteCobrancas,
    moveCobrancas
}