import React, { useState } from "react";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CashIcon from "@mui/icons-material/AttachMoney";
import ChatIcon from '@mui/icons-material/Chat';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GradingIcon from '@mui/icons-material/Grading';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getColorProfile } from '../../utils/appColors';
import BotoesGradiente from '../../components/BotoesGradiente';
import CriarCobrancaRapidaModal from "./CriarCobrancaRapidaModal";
import CriarCobrancaRapidaHonorariosModal from "./CriarCobrancaRapidaHonorariosModal";
import buscaAcesso from '../../utils/controleDeAcesso';


export default function Home() {
  const [cobrancaRapidaModalOpen, setCobrancaRapidaModalOpen] = useState(false);
  const [cobrancaRapidaHonorariosModalOpen, setCobrancaRapidaHonorariosModalOpen] = useState(false);

  const { corPrincipal,
    corPrincipalEscurecida,
    corPrincipalMaisClara,
    corSecundaria,
    corSecundariaEscurecida,
    corSecundariaMaisClara } = getColorProfile();


  const closeCobrancaRapidaModal = () => {
    setCobrancaRapidaModalOpen(false);
  };

  const closeCobrancaRapidaHonorariosModal = () => {
    setCobrancaRapidaHonorariosModalOpen(false);
  };

  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-[105%] h-[110vh] flex flex-col space-y-5 justify-start items-center">
        <h1 className="text-xl mb-8" style={{ color: corPrincipal }}>
          Bem-vindo(a),
          <a className="font-extrabold ml-[3px] pointer-events-none	" href="#" >
            {localStorage.getItem('customerUserName')}
          </a>
        </h1>

        {/* Gerar Cobrança Button */}
        <BotoesGradiente
          fromColor={corPrincipalMaisClara}
          toColor={corSecundariaEscurecida}
          Icon={WalletIcon}
          text='Carteiras'
          link="/carteiras"
          height="h-[140px]"
        />
        {buscaAcesso().acordos && (
          <>
            <BotoesGradiente
              fromColor={corPrincipal}
              toColor={corSecundariaEscurecida}
              Icon={GradingIcon}
              text='Acordos'
              link="/acordos"
              height="h-[140px]"
            />
            <BotoesGradiente
              fromColor={corPrincipalEscurecida}
              toColor={corSecundariaEscurecida}
              Icon={ReceiptIcon}
              text='Extrato'
              link="/extrato"
              height="h-[140px]"
            />
            <BotoesGradiente
              fromColor={corPrincipalEscurecida}
              toColor={corSecundariaEscurecida}
              Icon={TableRowsIcon}
              text='Todas Cobranças'
              link="/todasCobrancas"
              height="h-[100px]"
            />
          </>
        )}
        <BotoesGradiente
          fromColor={corPrincipal}
          toColor={corSecundariaMaisClara}
          Icon={CashIcon}
          text='Cobrança Rápida'
          onClick={() => setCobrancaRapidaModalOpen(true)}
          height="h-[140px]"
        />
        {buscaAcesso().cobranca_rapida_honorarios && (
          <BotoesGradiente
            fromColor={corPrincipal}
            toColor={corSecundariaEscurecida}
            Icon={CashIcon}
            text='Cobrança Rápida de Honorários'
            onClick={() => setCobrancaRapidaHonorariosModalOpen(true)}
            height="h-[140px]"
          />
        )}

        {/* <BotoesGradiente
          fromColor={corPrincipalEscurecida}
          toColor={corSecundaria}
          Icon={ChatIcon}
          text="Chat de Negociação (Em Breve)"
          isDisabled={true}
          height="h-[50px]"
          textColor='black'
        />
        <BotoesGradiente
          fromColor={corSecundariaMaisClara}
          toColor={corPrincipalMaisClara}
          Icon={FileUploadIcon}
          text="Arquivo CNAB (Em Breve)"
          isDisabled={true}
          height="h-[50px]"
          textColor='black'
        /> */}
      </div>
      {cobrancaRapidaModalOpen && <CriarCobrancaRapidaModal
        open={cobrancaRapidaModalOpen}
        onClose={closeCobrancaRapidaModal} />}
      {cobrancaRapidaHonorariosModalOpen && <CriarCobrancaRapidaHonorariosModal
        open={setCobrancaRapidaHonorariosModalOpen}
        onClose={closeCobrancaRapidaHonorariosModal} />}
    </div>
  );
}
