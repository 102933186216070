import React, { useEffect, useState } from "react";
import { getAcordoStatusButton } from "../utils/statusButton";
import { getColorProfile } from "../utils/appColors";


const StatusFilter = ({ statuses, onFilterChange, filteredStatus }) => {
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const { corPrincipal } = getColorProfile();

    useEffect(() => {
        if(filteredStatus !== selectedStatuses){
            setSelectedStatuses(filteredStatus);
        }
    }, [filteredStatus])

    const handleStatusToggle = (status) => {
        const updatedStatuses = selectedStatuses.includes(status)
            ? selectedStatuses.filter((s) => s !== status) // Remove se já estiver selecionado
            : [...selectedStatuses, status]; // Adiciona se não estiver selecionado
        setSelectedStatuses(updatedStatuses);

        onFilterChange(updatedStatuses); // Atualiza o estado e chama a função da API
    };

    return (
        <div className="flex flex-wrap gap-2 items-center">
            <div className="flex flex-wrap gap-2 justify-center">
                {statuses.map((status) => (
                    <div
                        key={status}
                        onClick={() => handleStatusToggle(status)}
                        style={{
                            border: selectedStatuses.includes(status) ? "2px solid " + corPrincipal : "none",
                            borderRadius: "8px",
                            padding: "5px",
                            cursor: "pointer",
                        }}
                    >
                        {getAcordoStatusButton(status)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StatusFilter;
