import React, { useState, useEffect } from "react";
import { IconButton, Drawer, List, ListItem, ListItemText, Box, Divider } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from '@mui/icons-material/Receipt';
import HomeIcon from "@mui/icons-material/Home";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import GradingIcon from '@mui/icons-material/Grading';
import LogoutIcon from '@mui/icons-material/Logout';
import CashIcon from "@mui/icons-material/AttachMoney";
import { Link } from "react-router-dom";
import { getLogoUrl } from '../api/ezcale/marca';
import { getColorProfile } from '../utils/appColors';
import buscaAcesso from '../utils/controleDeAcesso';

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [logoUrl, setLogoUrl] = useState('');
  const { corPrincipalEscurecida } = getColorProfile();

  const toggleDrawer = (open) => () => {
    setOpen(open);
  };

  useEffect(() => {
    setLogoUrl(getLogoUrl());
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="w-full">
      
{/* Gradiente de BG */}
  <div className="fixed top-0 left-1/2 transform -translate-x-1/2 w-[110%] h-[70px] bg-[linear-gradient(to_top,rgba(255,255,255,0),rgba(255,255,255,1)_90%)] z-40"></div>

{/* Navbar */}
      <nav className="fixed top-2 left-1/2 transform -translate-x-1/2 w-[96%] bg-gray-100 rounded-full shadow-lg z-50 h-[60px] flex justify-between items-center px-4 lg:px-8">
        <IconButton
          edge="start"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          className="lg:hidden text-gray-700 hover:text-gray-900 transition duration-300 ease-in-out"
        >
          <MenuIcon sx={{ color: corPrincipalEscurecida }} />
        </IconButton>

        <div className="absolute ml-[48%] transform -translate-x-1/2">
          <Link className="no-underline block" to={'/'}>
            <img src={logoUrl} alt="Logo" className="h-14" />
          </Link>
        </div>
      </nav>

      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: {
            backgroundColor: "#f3f4f6",
            width: isMobile ? "100vw" : "20vw",
          },
        }}
      >
        <Box className="transition-transform duration-500 ease-in-out h-full relative">
          <IconButton
            onClick={toggleDrawer(false)}
            style={{ position: "absolute", top: 5, right: 5 }}
            className="text-gray-700 hover:text-gray-900 transition duration-300 ease-in-out"
          >
            <CloseIcon sx={{ color: corPrincipalEscurecida }} />
          </IconButton>

          <div className="flex justify-center items-center mt-10 mb-10">
            <img src={logoUrl} alt="Logo" className="max-h-20" />
          </div>

          <div style={{ borderBottom: `1.8px solid ${corPrincipalEscurecida}` }} />

          <List className="space-y-1 mt-4">
            <Link className="no-underline block" to={'/'}>
              <ListItem button>
                <HomeIcon className="mr-4 text-gray-700" sx={{ color: corPrincipalEscurecida }} />
                <ListItemText primary={<span className="font-extrabold">Home</span>} sx={{ color: corPrincipalEscurecida }} className="hover:text-blue-500 transition duration-300 ease-in-out" />
              </ListItem>
            </Link>
            <Divider />
            <Link className="no-underline block" to={'/carteiras'}>
              <ListItem button>
                <WalletIcon className="mr-4 text-gray-700" sx={{ color: corPrincipalEscurecida }} />
                <ListItemText primary={<span className="font-extrabold">Carteiras</span>} sx={{ color: corPrincipalEscurecida }} className="text-gray-700 hover:text-blue-500 transition duration-300 ease-in-out" />
              </ListItem>
            </Link>
            <Divider />
            {buscaAcesso().acordos && (
              <>
                <Link className="no-underline block" to={'/acordos'}>
                  <ListItem button>
                    <GradingIcon className="mr-4 text-gray-700" sx={{ color: corPrincipalEscurecida }} />
                    <ListItemText primary={<span className="font-extrabold">Acordos</span>} sx={{ color: corPrincipalEscurecida }} className="text-gray-700 hover:text-blue-500 transition duration-300 ease-in-out" />
                  </ListItem>
                </Link>
                <Divider />
              </>
            )}
            {buscaAcesso().acordos && (
              <>
                <Link className="no-underline block" to={'/extrato'}>
                  <ListItem button>
                    <ReceiptIcon className="mr-4 text-gray-700" sx={{ color: corPrincipalEscurecida }} />
                    <ListItemText primary={<span className="font-extrabold">Extrato</span>} sx={{ color: corPrincipalEscurecida }} className="text-gray-700 hover:text-blue-500 transition duration-300 ease-in-out" />
                  </ListItem>
                </Link>
                <Divider />
              </>
            )}
          </List>

          <Box position="absolute" bottom={0} width="100%">
            <div style={{ borderBottom: `1.8px solid ${corPrincipalEscurecida}` }} />
            <Link className="no-underline block" to={'/login'}>
              <ListItem button>
                <LogoutIcon className="mr-4 text-gray-700" sx={{ color: corPrincipalEscurecida }} />
                <ListItemText primary={<span className="font-extrabold">Logout</span>} sx={{ color: corPrincipalEscurecida }} className="text-gray-700 hover:text-blue-500 transition duration-300 ease-in-out" />
              </ListItem>
            </Link>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
}
