import axios from 'axios';

async function buscaDadosCondominosNaoProcessados(carteiraId) {
    try {
        const endpoint = process.env.REACT_APP_API_BACKEND_CSI + '/importsJson/verDados/' + carteiraId;
        const { status, data } = await axios.get(endpoint);
        if (status == 200) {
            return data;
        }
        return {};
    }
    catch {
        return {};
    }
}


export {
    buscaDadosCondominosNaoProcessados
}