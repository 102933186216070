import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  TextField,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import handleCriaCobrancaRapidaHonorarios from './CriarCobrancaRapidaHonorariosService';
import Loader from '../../components/Loader/Loader';
import { getColorProfile } from '../../utils/appColors';
import { listaCustomers } from '../../api/ezcale/customer'; // dados dos ECs

const CriarCobrancaRapidaHonorariosModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateButtonEnabled, setIsCreateButtonEnabled] = useState(false);
  const [alerta, setAlerta] = useState({}); //Objeto Alerta
  const [alertaSelecaoEC, setAlertaSelecaoEC] = useState({}); //Objeto Alerta
  const { corPrincipalEscurecida } = getColorProfile();
  const [ecsList, setEcsList] = useState([]);
  const [ecHashId, setEcHashId] = useState('');
  const [selectedEC, setSelectedEC] = useState({});
  const [cobrancaData, setCobrancaData] = useState({
    nome_devedor: "",
    cpf_cnpj_devedor: "",
    logradouro_devedor: "",
    numero_devedor: "",
    bairro_devedor: "",
    cidade_devedor: "",
    estado_devedor: "",
    cep_devedor: "",
    valor_total_cobranca: 0,
    valor_total_honorarios: 0,
    valor_original: 0,
    valor_juros: 0,
    valor_multa: 0,
    valor_correcao_monetaria: 0,
    vencimento: useState(new Date().toISOString().substring(0, 10)),
    description: "",
    custo_cobranca: 15,
    telefone1: "",
    email1: "",
    url_faleconosco: '',
    nome_condominio: '',
    unidade_devedora: '',
    observacoes1: ''
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (cobrancaData.description !== '' &&
      cobrancaData.nome_devedor !== '' &&
      cobrancaData.cpf_cnpj_devedor !== '' &&
      cobrancaData.logradouro_devedor !== '' &&
      cobrancaData.valor_total_cobranca > 0 &&
      cobrancaData.cep_devedor !== ''
    ) {
      setIsCreateButtonEnabled(true);
    }
  }, [cobrancaData])

  useEffect(() => {
    const today = new Date();
    const trintaDiasNaFrente = new Date(today);
    trintaDiasNaFrente.setDate(today.getDate() + 30);
    // Formata a data no formato 'yyyy-mm-dd'
    const ano = trintaDiasNaFrente.getFullYear();
    const mes = String(trintaDiasNaFrente.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
    const dia = String(trintaDiasNaFrente.getDate()).padStart(2, '0');
    const newCobrancaData = { ...cobrancaData };
    newCobrancaData.vencimento = `${ano}-${mes}-${dia}`;
    setCobrancaData(newCobrancaData);
  }, []);

  const handleClose = () => {
    //Limpa o LocalStorage com Tokens Caso selecionados previamente
    localStorage.setItem('slAppToken', '');
    localStorage.setItem('slAccessToken', '');
    setEcHashId('');
    onClose(); //fecha o Modal
  }

  // Função para aplicar a máscara de telefone no formato brasileiro
  const handleTelefoneChange = (e, property) => {
    let input = e.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

    if (input.length > 11) input = input.slice(0, 11); // Limita o número de dígitos a 11 (padrão celular)

    // Aplica a máscara conforme o comprimento do input
    if (input.length > 2) {
      input = `(${input.slice(0, 2)}) ${input.slice(2, 7)}-${input.slice(7)}`;
    } else if (input.length > 0) {
      input = `(${input}`; // Inicia o código de área
    }

    handleCobrancaData(property, input);
  };


  const handleSelecaoEc = async (hashId) => {
    setIsLoading(true);

    //Preenche localStorage com EC selecionado
    ecsList.map(ec => {
      if (ec.hashId === hashId) {
        if (ec.customerTaxId === localStorage.getItem('customerUserTaxId')) {
          setAlertaSelecaoEC({
            type: 'error',
            titulo: 'Erro de Seleção de EC',
            descricao: 'Não é possível selecionar o Administrador como Beneficiário na Cobrança de Honorários'
          });
        } else {
          localStorage.setItem('customerUserName', ec.customerName);
          localStorage.setItem('customerUserTaxId', ec.customerTaxId);
          setEcHashId(hashId);
          setSelectedEC(ec);
          setAlertaSelecaoEC({});
        }
      }
    })
    setIsLoading(false);
  }

  const fetchCustomers = async () => {
    const customers = await listaCustomers();
    if (customers.length === 1) {
      if (customers[0].customerTaxId === localStorage.getItem('customerUserTaxId')) {
        setAlertaSelecaoEC({
          type: 'error',
          titulo: 'Erro de Seleção de EC',
          descricao: 'Não é possível selecionar o Administrador como Beneficiário na Cobrança de Honorários'
        });
      }
      else {
        setEcHashId(customers[0].hashId);
        localStorage.setItem('customerUserName', customers[0].customerName);
        localStorage.setItem('customerUserTaxId', customers[0].customerTaxId);
        handleSelecaoEc(customers[0].hashId);
        setSelectedEC(customers[0]);
      }
    }

    setEcsList(customers);
  }

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleCobrancaData = (property, value) => {
    const novaCobrancaData = { ...cobrancaData };
    novaCobrancaData[property] = value;
    if (property == 'nome_condominio' || property == 'unidade_devedora') {
      novaCobrancaData['description'] = novaCobrancaData['nome_condominio'] + " | " + novaCobrancaData['unidade_devedora'];
    }
    novaCobrancaData.valor_original = Number(novaCobrancaData.valor_total_honorarios);
    novaCobrancaData.valor_total_cobranca = Number(novaCobrancaData.valor_original);

    setCobrancaData(novaCobrancaData);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          width: { xs: "90%", sm: "60%", md: "60%" },
          maxHeight: "90vh", // Limita a altura do modal
          overflowY: "auto", // Habilita a barra de rolagem
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" sx={{ color: corPrincipalEscurecida }}>
          <b>Criar Cobrança Rápida de Honorários</b>
        </Typography>
        <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-10" />
        <FormControl fullWidth>
          <InputLabel id="simple-select-label">Selecione o EC</InputLabel>
          <Select
            label="Selecione o EC"
            value={ecHashId}
            onChange={(e) => handleSelecaoEc(e.target.value)}
            fullWidth
            sx={{ mb: 1.5 }}
          >
            {
              ecsList.map(customer =>
                <MenuItem key={customer.hashId} value={customer.hashId}>
                  {customer.statement_descriptor || customer.customerName} ({customer.customerTaxId})
                </MenuItem>)
            }
          </Select>
        </FormControl>
        {/* Mostra Alerta de Resultado do Processamento */}
        {Object.keys(alertaSelecaoEC).length > 0 && (
          <Alert className="signin-area" severity={alertaSelecaoEC.type}>
            <AlertTitle>{alertaSelecaoEC.titulo}</AlertTitle>
            {alertaSelecaoEC.descricao}
          </Alert>)}
        {ecHashId !== '' && ecHashId !== undefined && (
          <form>
            <Box sx={{
              mt: 2,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
            }}>
              <TextField
                label="Descrição da Cobrança *"
                fullWidth
                value={cobrancaData.description}
                onChange={(e) => handleCobrancaData('description', e.target.value)}
              />
            </Box>
            <Box sx={{
              mt: 2,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
            }}>
              <TextField
                label="Observação"
                fullWidth
                value={cobrancaData.observacoes1}
                onChange={(e) => handleCobrancaData('observacoes1', e.target.value)}
              />
            </Box>
            <Box sx={{
              mt: 2,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
            }}>
              <TextField
                label="Nome Devedor *"
                type="text"
                value={cobrancaData.nome_devedor}
                onChange={(e) => handleCobrancaData('nome_devedor', e.target.value)}
                sx={{ width: '100%', color: corPrincipalEscurecida }}
              />
              <TextField
                label="CPF/CNPJ Devedor *"
                type="text"
                value={cobrancaData.cpf_cnpj_devedor}
                onChange={(e) => handleCobrancaData('cpf_cnpj_devedor', e.target.value)}
                sx={{ width: isMobile ? '100%' : '60%', color: corPrincipalEscurecida, mr: '10px' }}
              />
            </Box>
            <Box sx={{
              mt: 2,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
            }}>
              <TextField
                label="CEP Devedor *"
                type="text"
                value={cobrancaData.cep_devedor}
                onChange={(e) => handleCobrancaData('cep_devedor', e.target.value)}
                sx={{ width: isMobile ? '100%' : '30%', color: corPrincipalEscurecida }}
              />
              <TextField
                label="Logradouro Devedor *"
                type="text"
                value={cobrancaData.logradouro_devedor}
                onChange={(e) => handleCobrancaData('logradouro_devedor', e.target.value)}
                sx={{ width: isMobile ? '100%' : '45%', color: corPrincipalEscurecida }}
              />
              <TextField
                label="Número Devedor *"
                type="text"
                value={cobrancaData.numero_devedor}
                onChange={(e) => handleCobrancaData('numero_devedor', e.target.value)}
                sx={{ width: isMobile ? '100%' : '25%', color: corPrincipalEscurecida, mr: '10px' }}
              />
            </Box>
            <Box sx={{
              mt: 2,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
            }}>
              <TextField
                label="Bairro Devedor *"
                type="text"
                value={cobrancaData.bairro_devedor}
                onChange={(e) => handleCobrancaData('bairro_devedor', e.target.value)}
                sx={{ width: isMobile ? '100%' : '40%', color: corPrincipalEscurecida }}
              />
              <TextField
                label="Cidade Devedor *"
                type="text"
                value={cobrancaData.cidade_devedor}
                onChange={(e) => handleCobrancaData('cidade_devedor', e.target.value)}
                sx={{ width: isMobile ? '100%' : '40%', color: corPrincipalEscurecida, mr: '10px' }}
              />
              <TextField
                label="UF Devedor *"
                type="text"
                value={cobrancaData.estado_devedor}
                onChange={(e) => handleCobrancaData('estado_devedor', e.target.value)}
                sx={{ width: isMobile ? '100%' : '20%', color: corPrincipalEscurecida }}
              />
            </Box>
            <Box sx={{
              mt: 2,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
            }}>
              <TextField
                label="Nome Condomínio"
                type="text"
                value={cobrancaData.nome_condominio}
                onChange={(e) => handleCobrancaData('nome_condominio', e.target.value)}
                sx={{ width: isMobile ? '100%' : '50%', color: corPrincipalEscurecida }}
              />
              <TextField
                label="Unidade Devedora"
                type="text"
                value={cobrancaData.unidade_devedora}
                onChange={(e) => handleCobrancaData('unidade_devedora', e.target.value)}
                sx={{ width: isMobile ? '100%' : '50%', color: corPrincipalEscurecida }}
              />
            </Box>
            <Box sx={{
              mt: 2,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
            }}>
              <TextField
                label="Valor Total de Honorários*"
                type="number"
                value={cobrancaData.valor_original}
                onChange={(e) => handleCobrancaData('valor_total_honorarios', e.target.value)}
                sx={{ width: isMobile ? '100%' : '35%', color: corPrincipalEscurecida, mr: '10px' }}
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>
                  }
                }}
              />
              <TextField
                label="Custo de Cobrança"
                type="number"
                value={cobrancaData.custo_cobranca}
                onChange={(e) => handleCobrancaData('custo_cobranca', e.target.value)}
                sx={{ width: isMobile ? '100%' : '35%', color: corPrincipalEscurecida }}
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>
                  }
                }}
              />
              <TextField
                label="Vencimento *"
                type="date"
                value={cobrancaData.vencimento}
                onChange={(e) => handleCobrancaData('vencimento', e.target.value)}
                slotProps={{
                  inputLabel: { shrink: true }
                }}
                sx={{ width: isMobile ? '100%' : '30%', color: corPrincipalEscurecida }}
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 1,
              }}
            >
            </Box>
            <Box sx={{
              mt: 2,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
            }}>
              <TextField
                label="Telefone Devedor"
                type="text"
                value={cobrancaData.telefone1}
                onChange={(e) => handleTelefoneChange(e, 'telefone1')}
                sx={{ width: '100%', color: corPrincipalEscurecida, mr: '10px' }}
              />
              <TextField
                label="E-mail Devedor"
                type="text"
                value={cobrancaData.email1}
                onChange={(e) => handleCobrancaData('email1', e.target.value)}
                sx={{ width: '100%', color: corPrincipalEscurecida }}
              />
            </Box>

            <Box sx={{
              mt: 2,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
            }}>
              <TextField
                label="URL Logo Secundária"
                type="text"
                value={cobrancaData.url_logo_secundario}
                onChange={(e) => handleCobrancaData('url_logo_secundario', e.target.value)}
                sx={{ width: '100%', color: corPrincipalEscurecida }}
              />
              <TextField
                label="Telefone Fale Conosco"
                type="text"
                value={cobrancaData.url_faleconosco}
                onChange={(e) => handleTelefoneChange(e, 'url_faleconosco')}
                sx={{ width: '100%', color: corPrincipalEscurecida }}
              />
            </Box>
            <Box sx={{
              mt: 2,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 1,
            }}>
              <TextField
                disabled={true}
                label="Valor Total Cobrança"
                type="number"
                value={cobrancaData.valor_total_cobranca}
                sx={{ width: '100%', color: corPrincipalEscurecida, mr: '10px' }}
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>
                  }
                }}
              />
              <TextField
                disabled={true}
                label="Valor Total Cobrança + Honorarios e Custo Cobrança"
                type="number"
                value={cobrancaData.valor_total_cobranca + cobrancaData.custo_cobranca}
                sx={{ width: '100%', color: corPrincipalEscurecida, mr: '10px' }}
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>
                  }
                }}
              />
            </Box>

            {/* Mostra Alerta de Resultado do Processamento */}
            {Object.keys(alerta).length > 0 && (
              <Alert className="signin-area" severity={alerta.type}>
                <AlertTitle>{alerta.titulo}</AlertTitle>
                {alerta.descricao}
              </Alert>)}

            {/* Botões de Ação */}
            {!isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Button
                  onClick={handleClose}
                  sx={{
                    background: "linear-gradient(to right, #f87171, #ef4444)", // Botão cancelar vermelho
                    color: "white",
                    borderRadius: "6px", // Border radius de 6px
                    height: "35px",
                    mr: 1,
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={!isCreateButtonEnabled}
                  variant="contained"
                  onClick={() => handleCriaCobrancaRapidaHonorarios(cobrancaData, setIsLoading, setAlerta, navigate)}
                  sx={{
                    background: "linear-gradient(to right, #66bb6a, #43a047)", // Botão criar
                    color: "white",
                    borderRadius: "6px", // Border radius de 6px
                    height: "35px",
                  }}
                >
                  Criar
                </Button>
              </Box>
            ) : (
              <Box className="w-full mt-5" sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Loader isLoading={isLoading} />
              </Box>
            )}
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default CriarCobrancaRapidaHonorariosModal;
