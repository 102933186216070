import React, { useState, useEffect, lazy, Suspense } from "react";
import GradingIcon from '@mui/icons-material/Grading';
import Tooltip from '@mui/material/Tooltip';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HistoryIcon from '@mui/icons-material/History';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Card,
  CardContent,
  Typography,
  Modal,
  Box,
  Button,
  Alert,
  AlertTitle,
  Divider,
  TextField
} from "@mui/material";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getColorProfile } from '../../utils/appColors';
import TextWithDoubleBorder from "../../components/TextWithDoubleBorder";
import { atualizaAcordo, listarAcordos } from '../../api/backend-csi/acordo';
import CircularLoaderWithCenterLogo from '../../components/Loader/CircularLoaderWithCenterLogo';
import { getAcordoStatusButton } from '../../utils/statusButton';
import DateRangePicker from "../../utils/DateRangePicker";
import { formatDate, subtraiXDiasDeHoje, formataDataHoraMySQLParaDataBrasileira } from "../../utils/dateFunctions";
import { listaCustomers } from '../../api/ezcale/customer'; // dados dos ECs
import { MudaStatusButtonModal } from '../../components/Modal/MudaStatusButtonModal';
import StatusFilter from "../../components/StatusFilter";

// Lazy Loading for modals
const BuscaCarteirasModal = lazy(() => import('./BuscaAcordos'));
const ConfissaoDividaModal = lazy(() => import('./Modal/ConfissaoDividaModal'));
const AnexosModal = lazy(() => import('./Modal/AnexosModal'));
const HistoricoModal = lazy(() => import('../../components/Modal/HistoricoModal'));

const theme = createTheme({
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
});

export default function Acordos() {
  const [customersTaxId, setCustomersTaxId] = useState([]); // Estado para o modal de busca
  const [searchModalOpen, setSearchModalOpen] = useState(false); // Estado para o modal de busca
  const [mudaStatusModalOpen, setMudaStatusModalOpen] = useState(false); // Estado para o modal de busca
  const { corPrincipal,
    corPrincipalEscurecida,
    corSecundaria,
    corSecundariaMaisClara } = getColorProfile();
  const [carteiras, setCarteiras] = useState([]);
  const [alerta, setAlerta] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAcordo, setSelectedAcordo] = useState(null);
  const [modalAnexosOpen, setModalAnexosOpen] = useState(false);
  const [modalConfissaoDividaOpen, setModalConfissaoDividaOpen] = useState(false);
  const [modalHistoricoOpen, setModalHistoricoOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchTerm, setSearchTerm] = useState('');  // Estado para o termo de busca
  const [buscaCarteirasData, setBuscaCarteirasData] = useState({
    dateto: formatDate(new Date()),
    datefrom: formatDate(new Date(new Date().setDate(new Date().getDate() - 360))),
    hashid: null,
    name: null
  });
  //Utilizado no DatePicker
  const [dateto, setDateto] = useState(formatDate(new Date()));
  const [datefrom, setDatefrom] = useState(subtraiXDiasDeHoje(7));
  const [selection, setSelection] = useState('7 Dias');
  const [statusSelecionado, setStatusSelecionado] = useState(""); //Utilizado para Atualizar o Status de Algum Acordo
  const statuses = ["Aprovado",
    "Reprovado",
    "Pendente",
    "Aguardando Aprovação",
    "Confissão de Dívida Gerada",
    'Remover']; // Todos Status possíveis * TO-DO: Pegar direto do Banco
  const [filteredStatuses, setFilteredStatuses] = useState(["Aprovado",
    "Reprovado",
    "Pendente",
    "Aguardando Aprovação",
    "Confissão de Dívida Gerada",
    'Remover']); //Utilizado para Filtrar a vista da tela de Acordos
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    //Verifica se a chamada de Customers ja foi realizada para não ser chamada novamente
    // Armazena os cnpjs ou cpfs dos usuarios na variavel customersTaxId (usada no buscaAcordosPorFiltro)
    const buscaCustomersQuandoCarregaOComponente = async () => {
      if (customersTaxId.length == 0) {
        const customers = await listaCustomers();
        const customersTaxId = customers?.map(customer => customer.customerTaxId);
        setCustomersTaxId(customersTaxId);
      }
    }

    buscaCustomersQuandoCarregaOComponente();
  }, []);

  useEffect(() => {
    const buscaAcordosPorFiltros = async () => {
      setIsLoading(true);
      if (customersTaxId.length > 0) {
        const newBuscaCarteiraData = { ...buscaCarteirasData };
        newBuscaCarteiraData.dateto = dateto;
        newBuscaCarteiraData.datefrom = datefrom;

        const response = await listarAcordos(datefrom, dateto, customersTaxId, filteredStatuses);
        setCarteiras(response);
      }
      setIsLoading(false);
    }

    //Chama a Busca de Acordos por Filtros Após alterar o Filtro, tem um debounce de 2 segundos caso 
    // o usuario queira selecionar mais filtros de uma vez
    buscaAcordosPorFiltros();

  }, [dateto, datefrom, filteredStatuses, customersTaxId]);


  const handleAnexoClick = (acordo) => {
    setSelectedAcordo(acordo);
    setModalAnexosOpen(true);
  };

  const handleHistoricoClick = (acordo) => {
    setSelectedAcordo(acordo);
    setModalHistoricoOpen(true);
  };

  const handleDateChange = async (range) => {
    setDatefrom(range.datefrom);
    setDateto(range.dateto);
  }

  // Função de filtro
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const cobrancasParafiltro = [...carteiras];
    const filtered = cobrancasParafiltro.filter((item) =>
      item.nome_devedor?.toLowerCase().includes(term) ||
      item.descricao_cobranca_original?.toLowerCase().includes(term) ||
      item.nome_credor?.toLowerCase().includes(term) ||
      item.status?.toLowerCase().includes(term)
    );

    setCarteiras(filtered);
  };

  const handleModalClose = () => {
    setModalAnexosOpen(false);
    setSelectedAcordo(null);
  };

  const handleConfissaoDividaModalClose = () => {
    setModalConfissaoDividaOpen(false);
  }

  const handleHistoricoModalClose = () => {
    setModalHistoricoOpen(false);
  }

  const closeSearchModal = () => {
    setSearchModalOpen(false);
  };

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(column);

    const sortedData = [...carteiras].sort((a, b) => {
      // Verifica valores nulos ou indefinidos e substitui por uma string vazia
      const valA = a[column] == null ? "" : a[column].trim();
      const valB = b[column] == null ? "" : b[column].trim();

      // Checa se os valores são numéricos
      const isNumeric = !isNaN(valA) && !isNaN(valB);

      // Checa se os valores são datas
      const isDate = !isNaN(Date.parse(valA)) && !isNaN(Date.parse(valB));

      let comparison = 0;

      if (isNumeric) {
        // Comparação para números
        comparison = Number(valA) - Number(valB);
      } else if (isDate) {
        // Comparação para datas
        comparison = new Date(valA) - new Date(valB);
      } else {
        // Comparação para strings com números embutidos
        const compareStringsWithNumbers = (str1, str2) => {
          const regex = /(\d+|\D+)/g; // Regexp para dividir a string em números e letras
          const parts1 = str1.match(regex) || [];
          const parts2 = str2.match(regex) || [];

          // Compara as partes separadas (números como inteiros e texto como strings)
          for (let i = 0; i < Math.min(parts1.length, parts2.length); i++) {
            const part1 = parts1[i];
            const part2 = parts2[i];

            const num1 = parseInt(part1, 10);
            const num2 = parseInt(part2, 10);

            // Se ambos são números, compara numericamente
            if (!isNaN(num1) && !isNaN(num2)) {
              if (num1 !== num2) {
                return num1 - num2;
              }
            } else {
              // Caso contrário, compara lexicograficamente
              const textComparison = part1.localeCompare(part2);
              if (textComparison !== 0) {
                return textComparison;
              }
            }
          }

          // Se todos os segmentos forem iguais, compara pelo comprimento da string
          return parts1.length - parts2.length;
        };

        comparison = compareStringsWithNumbers(valA, valB);
      }

      return isAsc ? comparison : -comparison;
    });

    setCarteiras(sortedData);
  };

  function timeAgoFromMySQL(mysqlDateTime) {
    if (mysqlDateTime == null) return '';

    const now = new Date();
    const date = new Date(mysqlDateTime);

    const secondsAgo = Math.floor((now - date) / 1000);

    const intervals = {
      ano: 31536000,  // 365 * 24 * 60 * 60
      mês: 2592000,  // 30 * 24 * 60 * 60
      semana: 604800,    // 7 * 24 * 60 * 60
      dia: 86400,      // 24 * 60 * 60
      hora: 3600,      // 60 * 60
      minuto: 60,
      segundo: 1
    };

    for (const [unit, seconds] of Object.entries(intervals)) {
      const count = Math.floor(secondsAgo / seconds);
      if (count > 0) {
        let plural = count > 1 ? 's' : '';
        if (unit == 'mês') {
          plural = 'es'
        }
        return (<b>{count} {unit}{plural} atrás</b>);
      }
    }

    return (<b>Agora mesmo</b>);
  }

  const handleSelecionaNovoStatusModal = async (novoStatus) => {
    setStatusSelecionado(novoStatus);
    //Chama atualziacao de Status aqui
    const updateData = {
      status: novoStatus,
      id: selectedAcordo.id
    }
    await atualizaAcordo(updateData, selectedAcordo.hash_cobranca_original);
    //Atualiza o Status do Acordo Também!
    setTimeout(() => {
      setMudaStatusModalOpen(false);
      window.location.reload();
    }, 100)
  };

  const handleFilterChange = async (selectedStatus) => {
    setFilteredStatuses(selectedStatus);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="w-full flex flex-col items-center">
        {!isLoading ? (
          <>
            <div className="w-full">
              <Typography variant="h4" component="h4" sx={{ color: corPrincipalEscurecida }}>
                <GradingIcon className="text-gray-700 m-2" sx={{ color: corPrincipalEscurecida }} />
                Acordos
              </Typography>
              <div
                className="mb-2"
                style={{ borderBottom: `3px solid ${corPrincipalEscurecida}` }} />
            </div>
            {/* Separador acima */}
            <Divider className="mb-2" sx={{ bgcolor: corPrincipalEscurecida }} />
            <div className="flex justify-between items-center w-full py-2">

              {/* Filtro por Data (Alinhado à esquerda) */}
              <div className="flex-shrink-0">
                <DateRangePicker
                  onDateChange={(range) => handleDateChange(range)}
                  dateTo={dateto}
                  dateFrom={datefrom}
                  setDateTo={setDateto}
                  setDateFrom={setDatefrom}
                  selection={selection}
                  setSelection={setSelection} />
              </div>

              {/* Botão de Ação (Alinhado à direita) */}
              <div className="flex-shrink-0">
                <TextField
                  variant="outlined"
                  placeholder="Procurar"
                  value={searchTerm}
                  onChange={handleSearch}
                  className="flex-grow mx-4"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "34px",
                      width: "140px",
                      marginRight: '6px',
                      marginLeft: '6px',
                      backgroundColor: "#f7f7f7",
                      borderRadius: "6px",
                      "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: corPrincipalEscurecida },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: corPrincipal },
                    },
                    "& .MuiOutlinedInput-notchedOutline": { borderColor: corSecundaria },
                  }}
                />
                <Button
                  variant={showFilters ? "contained" : "outlined"}
                  onClick={() => setShowFilters(!showFilters)}
                  sx={{
                    height: "34px",
                    width: 'fit-content',
                    maxWidth: '90px',
                    fontSize: '0.75rem',
                    color: showFilters ? "white" : corPrincipalEscurecida, // Texto branco se for 'contained'
                    borderColor: corPrincipal,
                    backgroundColor: showFilters ? corSecundariaMaisClara : "transparent", // Define a cor de fundo se for 'contained'
                    "&:hover": {
                      backgroundColor: showFilters ? corSecundaria : corSecundariaMaisClara, // Diferente no hover
                    },
                  }}
                  startIcon={<FilterListIcon />}
                >
                  Filtros
                </Button>
              </div>
            </div>

            {/* Div expansível que aparece ao clicar no botão de filtro */}
            {showFilters && (
              <div className="w-full flex flex-col items-center justify-center p-4 mt-2 rounded-lg shadow-lg">
                <Typography
                  variant="h4"
                  component="h4"
                  className="text-center w-full"
                  sx={{
                    color: corPrincipalEscurecida,
                    fontSize: "22px",
                    mb: '10px'
                  }}
                >
                  Filtrar por Status
                </Typography>
                <StatusFilter
                  statuses={statuses}
                  onFilterChange={handleFilterChange}
                  filteredStatus={filteredStatuses}
                  className="w-full"
                />
              </div>
            )}

            {/* <div className="w-full flex justify-between items-center mb-4">
              <DateRangePicker
                onDateChange={(range) => handleDateChange(range)}
                dateTo={dateto}
                dateFrom={datefrom}
                setDateTo={setDateto}
                setDateFrom={setDatefrom}
                selection={selection}
                setSelection={setSelection} /> */}
            {/* Ícone de Configurações */}
            {/* <div className="flex justify-evenly items-center pr-[7.5%]">
                <TextField
                  className="flex-shrink-0 shadow-sm w-full max-w-xs pt-[500px]"
                  variant="outlined"
                  placeholder="Procurar"
                  value={searchTerm}
                  onChange={handleSearch}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '40px', // Altura personalizada
                      width: '90%', // Largura mínima
                      backgroundColor: '#f7f7f7', // Cor de fundo customizada
                      borderRadius: '8px', // Borda arredondada
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: corPrincipalEscurecida, // Cor personalizada escurecida no hover
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: corPrincipal, // Cor personalizada quando o campo está em foco
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: corPrincipal, // Cor padrão
                    },
                  }}
                />
                <IconButton>
                  <Typography variant="h6" component="h6" sx={{ color: corPrincipal, fontSize: '20px' }}>
                    Mais Filtros
                  </Typography>
                  <FilterListIcon sx={{ color: corPrincipalEscurecida }} />
                </IconButton>
              </div>
            </div> */}

            {/* Mostra Alerta de Resultado do Processamento */}
            {Object.keys(alerta).length > 0 && (
              <Alert className="signin-area" severity={alerta.type} key={`id-${alerta.titulo}`}>
                <AlertTitle>{alerta.titulo}</AlertTitle>
                {alerta.descricao}
              </Alert>
            )}

            {/* Mobile version: Display as Cards */}
            <div className="lg:hidden w-full space-y-3 mt-4">
              {carteiras.map((carteira, index) => {
                const backgroundColor = index % 2 === 0 ? '#FFF9F5' : '#F5F9FF';
                return (< Card
                  key={carteiras.hashId}
                  style={{
                    backgroundColor,
                    width: "100%",
                    boxShadow: 3,
                    borderRadius: "16px",
                  }}
                >
                  <CardContent >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-2">
                        <WalletIcon className="text-gray-700" sx={{ color: corPrincipalEscurecida }} />
                        <TextWithDoubleBorder text={carteira.nome_devedor} innerColor={corPrincipalEscurecida} outerColor='black' borderWidth='1.5' />
                      </div>
                    </div>
                    <div className="mt-4">
                      <Typography color="textSecondary">{carteira.descriptor}</Typography>
                      <Typography variant="body2">Credor: {carteira.nome_credor}</Typography>
                      <Typography variant="body2">Descrição: {carteira.descricao_cobranca_original}</Typography>
                      <Typography variant="body2">Criado em: {formataDataHoraMySQLParaDataBrasileira(carteira.created_at)}</Typography>
                      <Typography variant="body2">Última Atualização: {formataDataHoraMySQLParaDataBrasileira(carteira.updated_at, 3)} - {timeAgoFromMySQL(carteira.updated_at)}</Typography>
                      <Typography variant="body2">Proposta enviada em: {formataDataHoraMySQLParaDataBrasileira(carteira.data_envio, 3)} - {timeAgoFromMySQL(carteira.data_envio)}</Typography>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="flex justify-start">
                        {getAcordoStatusButton(carteira.status)}
                      </div>
                      <div className="flex space-x-1">
                        <Link to={'/detalhesAcordo/' + carteira.hash_carteira}>
                          <IconButton>
                            <FindInPageIcon sx={{ color: corPrincipalEscurecida }} />
                          </IconButton>
                        </Link>
                        <Link to={'/cobrancas/' + carteira.hash_carteira}>
                          <IconButton>
                            <AccountBalanceWalletIcon sx={{ color: corPrincipalEscurecida }} />
                          </IconButton>
                        </Link>
                        <IconButton onClick={() => handleAnexoClick(carteira)}>
                          <AttachFileIcon sx={{ color: corPrincipalEscurecida }} />
                        </IconButton>
                        <IconButton onClick={() => handleHistoricoClick(carteira)}>
                          <HistoryIcon sx={{ color: corPrincipalEscurecida }} />
                        </IconButton>
                      </div>
                    </div>
                  </CardContent>
                </Card>)
              })}
            </div>

            {/* Desktop version: Display as Table */}
            <TableContainer component={Paper} className="w-[90%] shadow-lg hidden lg:block" > {/* hidden lg:block */}
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: corPrincipal, color: 'white' }}>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("created_at")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Criado Em</b> {sortColumn === "created_at" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("nome_devedor")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Nome</b> {sortColumn === "nome_devedor" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("descricao_cobranca_original")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Descrição</b> {sortColumn === "descricao_cobranca_original" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("nome_credor")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Credor</b> {sortColumn === "nome_credor" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("status")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Status</b> {sortColumn === "status" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("updated_at")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Ultima Atualização</b> {sortColumn === "updated_at" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      className="cursor-pointer"
                      align="center"
                      onClick={() => handleSort("data_envio")}
                      sx={{ borderRight: `1px solid ${corSecundariaMaisClara}`, color: 'white' }}>
                      <b>Proposta Enviada</b> {sortColumn === "data_envio" ?
                        (sortDirection === "asc" ? "↑" : "↓") : "↑↓"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: 'white' }}>
                      <b>Ação</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(carteiras) && carteiras?.map((carteira, index) => {
                    const backgroundColor = index % 2 === 0 ? '#FFF9F5' : '#F5F9FF';
                    return (
                      <TableRow key={carteira.hashId} sx={{ backgroundColor, '&:hover': { backgroundColor: '#e0e0e0' } }}>
                        <TableCell
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          {formataDataHoraMySQLParaDataBrasileira(carteira.created_at)}
                          {/* {carteira.created_at} */}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          {carteira.nome_devedor}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          {carteira.descricao_cobranca_original}
                        </TableCell>
                        <TableCell
                          className="overflow"
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          {carteira.nome_credor}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          {getAcordoStatusButton(carteira.status, () => { setMudaStatusModalOpen(true); setSelectedAcordo(carteira) })}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          <div className="flex flex-col">
                            {formataDataHoraMySQLParaDataBrasileira(carteira.updated_at, 3)}
                            <Divider className="mb-2" sx={{ bgcolor: corPrincipalEscurecida }} />
                            {timeAgoFromMySQL(carteira.updated_at)}
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ borderRight: `1px solid ${corSecundariaMaisClara}` }}>
                          <div className="flex flex-col">
                            <p>{formataDataHoraMySQLParaDataBrasileira(carteira.data_envio, 3)}</p>
                            <Divider className="mb-2" sx={{ bgcolor: corPrincipalEscurecida }} />
                            {timeAgoFromMySQL(carteira.data_envio)}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <Link to={'/detalhesAcordo/' + carteira.hash_carteira}>
                            <Tooltip title="Página de Detalhes do Acordo" arrow>
                              <IconButton>
                                <FindInPageIcon sx={{ color: corPrincipalEscurecida }} />
                              </IconButton>
                            </Tooltip>
                          </Link>
                          <Link to={'/cobrancas/' + carteira.hash_carteira}>
                            <Tooltip title="Carteira do Acordo" arrow>
                              <IconButton>
                                <AccountBalanceWalletIcon sx={{ color: corPrincipalEscurecida }} />
                              </IconButton>
                            </Tooltip>
                          </Link>
                          <Tooltip title="Arquivos Anexos do Acordo" arrow>
                            <IconButton onClick={() => handleAnexoClick(carteira)}>
                              <AttachFileIcon sx={{ color: corPrincipalEscurecida }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Histórico do Acordo" arrow>
                            <IconButton onClick={() => handleHistoricoClick(carteira)}>
                              <HistoryIcon sx={{ color: corPrincipalEscurecida }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>)
                  }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <CircularLoaderWithCenterLogo />
          </div>
        )}


        {/* Modais */}
        <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
          <CircularLoaderWithCenterLogo />
        </div>}>
          <AnexosModal
            open={modalAnexosOpen}
            onClose={handleModalClose}
            selectedAcordo={selectedAcordo}
            setModalConfissaoDividaOpen={setModalConfissaoDividaOpen}
          />
        </Suspense>

        <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
          <CircularLoaderWithCenterLogo />
        </div>}>
          <ConfissaoDividaModal
            open={modalConfissaoDividaOpen}
            onClose={handleConfissaoDividaModalClose}
            selectedAcordo={selectedAcordo} />
        </Suspense>

        <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
          <CircularLoaderWithCenterLogo />
        </div>}>
          <HistoricoModal
            open={modalHistoricoOpen}
            onClose={handleHistoricoModalClose}
            hashInvoiceId={selectedAcordo?.hash_cobranca_original} />
        </Suspense>

        <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
          <CircularLoaderWithCenterLogo />
        </div>}>
          {searchModalOpen && <BuscaCarteirasModal open={searchModalOpen} onClose={closeSearchModal} />}
        </Suspense>

        <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
          <CircularLoaderWithCenterLogo />
        </div>}>
          {mudaStatusModalOpen && <MudaStatusButtonModal
            open={mudaStatusModalOpen}
            onClose={() => setMudaStatusModalOpen(false)}
            statuses={statuses}
            handleSelecionarStatus={handleSelecionaNovoStatusModal}
          />}
        </Suspense>



      </div>
    </ThemeProvider>
  );
}
