import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_EZCALE_SECRET_KEY, // ou sua URL fixa
  headers: {
    'Content-Type': 'application/json',
  }
});

// Intercepta todas as respostas, mesmo status 200
axiosInstance.interceptors.response.use(
  (response) => {
    const { status_code, error_code } = response.data;

    if (status_code === 408 || error_code === 'ERR_SESSION_EXPIRED') {
      localStorage.removeItem('user_token');
      window.location.href = '/login';
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
