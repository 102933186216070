
const colorProfiles = {
    'default': { //cores da CSI 
        corPrincipal: '#4A8532',
        corPrincipalEscurecida: darkenColor('#4A8532', 25),
        corPrincipalMaisClara: brightenColor('#4A8532', 25),
        corSecundaria: '#B8D899',
        corSecundariaEscurecida: darkenColor('#B8D899', 25),
        corSecundariaMaisClara: brightenColor('#B8D899', 25),
    },
    'login.atcsadvogados.com.br': {
        corPrincipal: '#D9C381',
        corPrincipalEscurecida: darkenColor('#D9C381', 25),
        corPrincipalMaisClara: brightenColor('#D9C381', 55),
        corSecundaria: '#A99463',
        corSecundariaEscurecida: darkenColor('#A99463', 25),
        corSecundariaMaisClara: brightenColor('#A99463', 25),
    },
    'login.imovelpay.com.br': {
        corPrincipal: '#00825D',
        corPrincipalEscurecida: darkenColor('#00825D', 25),
        corPrincipalMaisClara: brightenColor('#00825D', 45),
        corSecundaria: '#9F9F9F',
        corSecundariaEscurecida: darkenColor('#9F9F9F', 25),
        corSecundariaMaisClara: brightenColor('#9F9F9F', 25),
    },
    'login.cartoriopay.com': {
        corPrincipal: '#E31F26',
        corPrincipalEscurecida: darkenColor('#E31F26', 45),
        corPrincipalMaisClara: brightenColor('#E31F26', 15),
        corSecundaria: '#FBAF3A',
        corSecundariaEscurecida: darkenColor('#FBAF3A', 44),
        corSecundariaMaisClara: brightenColor('#FBAF3A', 15),
    },
    'login.mgex.com.br': {
        corPrincipal: '#4A8532',
        corPrincipalEscurecida: darkenColor('#4A8532', 25),
        corPrincipalMaisClara: brightenColor('#4A8532', 45),
        corSecundaria: '#B8D899',
        corSecundariaEscurecida: darkenColor('#B8D899', 25),
        corSecundariaMaisClara: brightenColor('#B8D899', 25),
    },
};


function darkenColor(hex, percent) {
    // Convert hex to RGB
    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);

    // Decrease each RGB component by the given percentage
    r = Math.floor(r * (1 - percent / 100));
    g = Math.floor(g * (1 - percent / 100));
    b = Math.floor(b * (1 - percent / 100));

    // Ensure the values are within the valid range [0, 255]
    r = r < 0 ? 0 : r;
    g = g < 0 ? 0 : g;
    b = b < 0 ? 0 : b;

    // Convert RGB back to hex and return
    return `#${(r.toString(16).padStart(2, '0'))}${(g.toString(16).padStart(2, '0'))}${(b.toString(16).padStart(2, '0'))}`;
}


function brightenColor(hex, percent) {
    // Convert hex to RGB
    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);

    // Increase each RGB component by the given percentage
    r = Math.min(255, Math.floor(r * (1 + percent / 100)));
    g = Math.min(255, Math.floor(g * (1 + percent / 100)));
    b = Math.min(255, Math.floor(b * (1 + percent / 100)));

    // Convert RGB back to hex and return
    return `#${(r.toString(16).padStart(2, '0'))}${(g.toString(16).padStart(2, '0'))}${(b.toString(16).padStart(2, '0'))}`;
}

export function getColorProfile() {

    const domainLogo = localStorage.getItem('portalDomainLogo');
    
    if(domainLogo) {
        const domainUrlFromLogo = domainLogo.split('https://')[1].split('/')[0];
        return colorProfiles[domainUrlFromLogo] || colorProfiles['default'];
    }

    return colorProfiles['default'];
}
