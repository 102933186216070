import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Box,
    TextField,
    CircularProgress
} from "@mui/material";
import { buscaDadosAcordoPorCarteira } from "../../api/backend-csi/acordo";
import { getLogoUrl } from "../../api/ezcale/marca";
import { getColorProfile } from "../../utils/appColors";
import { getAcordoStatusButton } from '../../utils/statusButton';
import CircularLoaderWithCenterLogo from '../../components/Loader/CircularLoaderWithCenterLogo';
import { downloadExtratoDivida } from '../../api/backend-csi/extrato';
import AprovacaoModal from "./AprovacaoModal";
import ReprovacaoModal from "./ReprovacaoModal";
import { enviaEmail } from '../../api/ezcale/notificacoes';
import { geraCodigoAutenticacao } from '../../api/backend-csi/acordo';
import { buscaCodigoSentAt } from '../../api/backend-csi/acordo';

const DetalhesAcordo = () => {
    const { hashCarteiraId } = useParams();
    const [cobrancaOriginal, setCobrancaOriginal] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDownloadExtrato, setIsLoadingDownloadExtrato] = useState(false);
    const [acordo, setAcordo] = useState({});
    const [parcelas, setParcelas] = useState([]);
    const [honorarios, setHonorarios] = useState([]);
    const [condominio, setCondominio] = useState([]);
    const [valorHonorarios, setValorHonorarios] = useState(0);
    const [rejeitarProposta, setRejeitarProposta] = useState(false);
    const [mostrarAprovacaoModal, setMostrarAprovacaoModal] = useState(false);
    const [mostrarReprovacaoModal, setMostrarReprovacaoModal] = useState(false);
    const [isLoadingModalAprovacao, setIsLoadingModalAprovacao] = useState(false);
    const [codigoValidacao, setCodigoValidacao] = useState('');
    const [codigoSentAt, setCodigoSentAt] = useState([]);
    const { corPrincipal, corPrincipalEscurecida, corSecundariaEscurecida, corSecundaria } = getColorProfile();

    useEffect(() => {
        const getDetalhesAcordo = async (id) => {
            const acordoDetails = await buscaDadosAcordoPorCarteira(id);
            if (acordoDetails?.acordo?.id) {
                setAcordo(acordoDetails.acordo);
                setParcelas(acordoDetails.parcelas);
                setHonorarios(acordoDetails.honorarios);
                setCondominio(acordoDetails.condominio);
                setCobrancaOriginal(acordoDetails.acordo.hash_cobranca_original);
                if (acordoDetails?.custom_fields?.valor_total_honorarios) {
                    setValorHonorarios(acordoDetails.custom_fields.valor_total_honorarios);
                }
                else if (acordoDetails?.custom_fields?.custom_honorarios_fee) {
                    const honorariosCalculado = acordoDetails.custom_fields.custom_honorarios_fee * acordoDetails.custom_fields.valor_original;
                    setValorHonorarios(honorariosCalculado);
                }
                else {
                    if (acordoDetails?.custom_fields?.valor_original) {
                        const honorariosCalculado = 0.2 * acordoDetails.custom_fields.valor_original;
                        setValorHonorarios(honorariosCalculado);
                    }
                }
            }
        };
        getDetalhesAcordo(hashCarteiraId);
    }, [hashCarteiraId]);

    const abreModalDeAprovacao = async () => {
        setIsLoadingModalAprovacao(true);
        const codigoValidacao = await geraCodigoAutenticacao(acordo.id);
        setCodigoValidacao(codigoValidacao);
        const codigoSentAt = await buscaCodigoSentAt(acordo.id);
        setCodigoSentAt(codigoSentAt);
        setMostrarAprovacaoModal(true);
        setIsLoadingModalAprovacao(false);
    }

    const fechaModalDeAprovacao = () => {
        setMostrarAprovacaoModal(false);
    };

    const abreModalDeReprovacap = async () => {
        setMostrarReprovacaoModal(true);
    }

    const fechaModalDeReprovacao = () => {
        setMostrarReprovacaoModal(false);
    };

    const combinedData = [...parcelas].sort(
        (a, b) => new Date(a.vencimento) - new Date(b.vencimento)
    );

    return (
        !acordo?.id ? (<div className="w-full h-full flex items-center justify-center">
            <CircularLoaderWithCenterLogo />
        </div>) : (
            <div className="min-h-screen bg-gray-50 flex flex-col items-center p-5">
                <div className="flex items-center justify-between w-full px-4 max-w-3xl">
                    <img
                        src={getLogoUrl()}
                        alt="Logo"
                        className="w-32 h-auto mb-6"
                    />
                    {getAcordoStatusButton(acordo?.status)}
                </div>
                {mostrarAprovacaoModal && codigoValidacao != '' && (<AprovacaoModal
                    open={true}
                    onClose={fechaModalDeAprovacao}
                    codigoValidacao={codigoValidacao}
                    acordo={acordo}
                    condominio={condominio}
                    codigoSentAt={codigoSentAt}
                    abreModalDeAprovacao={abreModalDeAprovacao}                    
                />)}
                {mostrarReprovacaoModal && (<ReprovacaoModal
                    open={true}
                    onClose={fechaModalDeReprovacao}
                    acordo={acordo}
                    condominio={condominio}
                />)}
                {/* Informações da Cobrança */}
                <div className="bg-white shadow-lg rounded-lg w-full max-w-3xl p-6 mb-6">
                    <h2
                        style={{ color: corPrincipalEscurecida }}
                        className="text-2xl font-bold mb-4 text-center"
                    >
                        Informações da Cobrança
                    </h2>
                    <p><strong>Devedor:</strong> {acordo?.nome_devedor} ({acordo?.cpf_cnpj_devedor})</p>
                    <p><strong>Descrição:</strong> {acordo?.descricao_cobranca_original}</p>
                    <p><strong>Vencimento:</strong> {new Date(acordo?.vencimento_cobranca_original).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}</p>
                    <p><strong>Valor Original:</strong> R$ {parseFloat(acordo?.valor_original_cobranca_original ?? 0).toFixed(2)}</p>
                    <p><strong>Valor Juros:</strong> R$ {parseFloat(acordo?.valor_juros_cobranca_original ?? 0).toFixed(2)}</p>
                    <p><strong>Valor Multa:</strong> R$ {parseFloat(acordo?.valor_multa_cobranca_original ?? 0).toFixed(2)}</p>
                    <p><strong>Valor Correção Monetária:</strong> R$ {parseFloat(acordo?.valor_correcao_igpm_cobranca_original ?? 0).toFixed(2)}</p>
                    {/* <p><strong>Valor de Honorários:</strong> R$ {parseFloat(acordo?.valor_honorarios_cobranca_original).toFixed(2)}</p>
                    <p><strong>Valor Total Corrigido:</strong> R$ {parseFloat(acordo.valor_total_cobranca_original).toFixed(2)}</p> */}
                    {/* <Divider className="mb-2 mt-2" /> */}
                    {isLoadingDownloadExtrato ? (
                        <div className="justify-self-end">
                            <CircularProgress sx={{ color: corPrincipal }} />
                        </div>
                    ) : (
                        <div className="justify-self-end">
                            <Button
                                variant="contained"
                                sx={{
                                    background: `linear-gradient(to right, ${corSecundaria}, ${corSecundariaEscurecida})`,
                                    color: "white",
                                    borderRadius: "6px",
                                    fontSize: "1rem",
                                    height: "35px",
                                    marginTop: "15px"
                                }}
                                onClick={() =>
                                    downloadExtratoDivida(cobrancaOriginal, setIsLoadingDownloadExtrato)}
                            >
                                Download Extrato da Dívida
                            </Button>
                        </div>
                    )}
                </div >
                {(acordo?.status === 'Pendente' || acordo?.status === 'Aguardando Aprovação') && (
                    <>
                        {!rejeitarProposta && (
                            <div className="flex flex-row mb-4">
                                <Button
                                    variant="contained"
                                    onClick={() => abreModalDeReprovacap()}
                                    sx={{
                                        background: "linear-gradient(to right, #f87171, #ef4444)", // Botão cancelar vermelho
                                        color: "white",
                                        borderRadius: "6px", // Border radius de 6px
                                    }}
                                    className="w-30 h-15 text-lg mr-5"
                                >
                                    Rejeitar Acordo
                                </Button>
                                <Button
                                    onClick={() => abreModalDeAprovacao()}
                                    variant="contained"
                                    sx={{
                                        background: "linear-gradient(to right, #66bb6a, #43a047)", // Botão criar
                                        color: "white",
                                        borderRadius: "6px", // Border radius de 6px
                                        marginLeft: "10px"
                                    }}
                                    className="w-[280px] h-[75px] text-lg"
                                >
                                    {!isLoadingModalAprovacao ?
                                        'Aprovar Acordo' :
                                        <CircularProgress sx={{ color: corPrincipal }} />
                                    }
                                </Button>
                            </div>
                        )}
                    </>
                )}
                {
                    !isLoading && (
                        <div className="flex justify-center gap-4 mt-1 w-full max-w-3xl">
                            {acordo?.contraProposta && acordo?.status === 'Reprovado' && (
                                <TextField
                                    disabled
                                    type="text"
                                    minRows={5}
                                    maxRows={500}
                                    multiline
                                    label="Contra Proposta / Motivo"
                                    value={acordo.contraProposta}
                                    className="border rounded-lg w-full"
                                    slotProps={{
                                        inputLabel: { shrink: true }
                                    }}
                                />
                            )}
                        </div >
                    )
                }
                {/* Tabela de Proposta de Acordo */}
                < div className="bg-white shadow-lg rounded-lg w-full max-w-3xl p-6 mb-6" >
                    <h2
                        style={{ color: corPrincipalEscurecida }}
                        className="text-2xl font-bold mb-4 text-center"
                    >
                        Parcelas do Acordo
                    </h2>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead style={{ backgroundColor: corPrincipal }}>
                                <TableRow>
                                    {/* <TableCell style={{ color: "white" }}><strong>Descrição</strong></TableCell> */}
                                    <TableCell style={{ color: "white" }}><strong>Vencimento</strong></TableCell>
                                    <TableCell style={{ color: "white" }}><strong>Valor Total</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {combinedData.map((item, index) => (
                                    <TableRow key={index}>
                                        {/* <TableCell>{item.description}</TableCell> */}
                                        <TableCell>{new Date(item.vencimento).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}</TableCell>
                                        <TableCell>
                                            R$ {parseFloat(item.valor_total_cobranca || item.valor_total_honorarios).toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div >
            </div >
        ));
};

export default DetalhesAcordo;
