import React, { useEffect, useState } from "react";
import {
    Menu,
    MenuItem,
    Divider
} from "@mui/material";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GradingIcon from '@mui/icons-material/Grading';
import HistoryIcon from "@mui/icons-material/History";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import GavelIcon from '@mui/icons-material/Gavel';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { downloadExtratoDivida } from '../../api/backend-csi/extrato';
import buscaAcesso from '../../utils/controleDeAcesso';
import { copiarLinkDaFaturaParaClipboard } from './CobrancaMenuService';

const CobrancasMenu = ({
    anchorEl,
    anchorReference,
    anchorPosition,
    handleClose,
    setAcordoModalOpen,
    setSegundaViaModalOpen,
    setDarBaixaModalOpen,
    selectedCobranca,
    setIsLoading,
    setModalHistoricoOpen,
    setRegistraProcessoModalOpen
}) => {
    const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {
        if (anchorEl === null) {
            setOpenMenu(false);
        }
        else {
            setOpenMenu(true);
        }
    }, [anchorEl])

    return (
        <Menu
            open={Boolean(anchorPosition) || anchorEl}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition ? { top: anchorPosition.top, left: anchorPosition.left } : undefined}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
            <MenuItem onClick={handleClose}
                component="a"
                href={localStorage.getItem('portalDomain') + "/bcv2.php?hid=" + selectedCobranca?.hashInvoiceId}
                target="_blank">
                <AttachMoneyIcon sx={{ mr: 1 }} /> Ver Fatura
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { copiarLinkDaFaturaParaClipboard(selectedCobranca?.hashInvoiceId); handleClose() }}>
                <ContentCopyIcon sx={{ mr: 1 }} /> Copiar Link da Fatura
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { setModalHistoricoOpen(true); handleClose() }}>
                <HistoryIcon sx={{ mr: 1 }} /> Ver Histórico
            </MenuItem>
            <Divider />
            {buscaAcesso().acordos && (
                <MenuItem onClick={() => { setAcordoModalOpen(true); handleClose() }}>
                    <GradingIcon sx={{ mr: 1 }} /> Criar Acordo
                </MenuItem>
            )}
            {buscaAcesso().acordos && (<Divider />)}

            {buscaAcesso().cobranca.cobranca_extrajudicial && (
                <MenuItem onClick={() => { setSegundaViaModalOpen(true); handleClose() }}>
                    <FileCopyIcon sx={{ mr: 1 }} /> Gerar 2ª Via
                </MenuItem>)}
            {buscaAcesso().cobranca.cobranca_extrajudicial && (<Divider />)}

            <MenuItem onClick={() => { setDarBaixaModalOpen(true); handleClose() }}>
                <CancelPresentationIcon sx={{ mr: 1 }} /> Baixar Cobrança
            </MenuItem>
            <Divider />
            {buscaAcesso().cobranca.cobranca_extrajudicial && (
                <MenuItem onClick={() => {
                    downloadExtratoDivida(selectedCobranca.hashInvoiceId, setIsLoading);
                    handleClose()
                }}>
                    <ReceiptLongIcon sx={{ mr: 1 }} /> Download Extrato Dívida
                </MenuItem>)}
            {buscaAcesso().cobranca.cobranca_extrajudicial && (<Divider />)}
            {/* <MenuItem onClick={() => handleDelete(cobranca.id)}>
                          <DeleteIcon sx={{ mr: 1 }} /> Remover Cobrança
                        </MenuItem> */}
            {buscaAcesso().cobranca.editar_processo && (
                <MenuItem onClick={() => {
                    setRegistraProcessoModalOpen(true);
                    handleClose();
                }}>
                    <GavelIcon sx={{ mr: 1 }} /> Registrar Jurídico (Processo)
                </MenuItem>)}
        </Menu>
    )
}

export default CobrancasMenu;