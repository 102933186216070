const copiarLinkDaFaturaParaClipboard = async (hashInvoiceId) => {
    const link = localStorage.getItem('portalDomain') + "/bcv2.php?hid=" + hashInvoiceId;
    await navigator.clipboard.writeText(link);
    alert('URL copiada para área de transferência: ' + link)
};

export {
    copiarLinkDaFaturaParaClipboard
};

