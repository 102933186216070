import React, { useEffect, useState, useMemo } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { Box, Typography, TextField, Button, useMediaQuery, Link } from "@mui/material";
import { getColorProfile } from '../../utils/appColors';
import { buscaCobrancas } from '../../api/ezcale/cobrancas';
import { formatDate, subtraiXDiasDeHoje } from '../../utils/dateFunctions';
import DateRangePicker from "../../utils/DateRangePicker";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CircularLoaderWithCenterLogo from '../../components/Loader/CircularLoaderWithCenterLogo';
import * as XLSX from 'xlsx';
import { getInvoiceStatusButton } from '../../utils/statusButton';

export default function TabelaDeCobrancas() {
  const [cobrancas, setCobrancas] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [checkboxCobrancasSelecionadas, setCheckboxCobrancasSelecionadas] = useState([]);
  const [datefrom, setDatefrom] = useState(subtraiXDiasDeHoje(7));
  const [dateto, setDateto] = useState(formatDate(new Date()));
  const [selection, setSelection] = useState("7 Dias");
  const [isLoading, setIsLoading] = useState(true);

  const { corPrincipal, corPrincipalEscurecida } = getColorProfile();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const headerClass = 'font-bold';
  const headerStyle = '';

  useEffect(() => {
    const fetchCobrancas = async () => {
      setIsLoading(true);
      const data = await buscaCobrancas('all', datefrom, dateto);
      setCobrancas(data);
      setIsLoading(false);
    };
    fetchCobrancas();
  }, [datefrom, dateto]);

  const cobrancasFiltradas = useMemo(() => {
    return cobrancas.filter(c =>
      c.nome_devedor?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      c.descriptor?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      c.email1?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [cobrancas, searchTerm]);

  const renderLink = (text, hash) => {
    const domain = window.location.origin;
    // Desabilitado temporariamente pois o hash não esta vindo corretamente da API de Todas as Cobranças
    return (
      <Link
        href={`${domain}/cobrancas/${hash}`}
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
      >
        {text}
      </Link>
    );
  };

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Criado Em',
      flex: 1,
      headerClassName: `${headerClass}`,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (!params.value) return '';
        const [date] = params.value.split(' ');
        const [y, m, d] = date.split('-');
        return `${d}/${m}/${y}`;
      }
    },
    {
      field: 'nome_devedor',
      headerName: 'Nome',
      flex: 2,
      headerClassName: `${headerClass}`,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => renderLink(params.value, params.row?.hashInvoiceGroupId)
    },
    {
      field: 'descriptor',
      headerName: 'Descrição',
      flex: 2,
      headerClassName: `${headerClass}`,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => renderLink(params.value, params.row?.hashInvoiceGroupId)
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      headerClassName: `${headerClass}`,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => getInvoiceStatusButton(params.value)
    },
    {
      field: 'valor_total_cobranca',
      headerName: 'Valor',
      flex: 1,
      headerClassName: `${headerClass}`,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const val = params.value;
        return val ? `R$ ${val.replace('.', ',')}` : '';
      }
    },
    {
      field: 'vencimento',
      headerName: 'Vencimento',
      flex: 1,
      headerClassName: `${headerClass}`,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (!params.value) return '';
        const [date] = params.value.split(' ');
        const [y, m, d] = date.split('-');
        return `${d}/${m}/${y}`;
      }
    },
    {
      field: 'email1',
      headerName: 'E-mail',
      flex: 2,
      headerClassName: `${headerClass}`,
      align: 'center',
      headerAlign: 'center'
    },
  ];

  const rows = cobrancasFiltradas.map((cobranca) => ({
    id: cobranca.hashInvoiceId, //Obrigatorio para o DataGrid
    ...cobranca
  }));

  const handleExport = () => {
    const exportData = cobrancasFiltradas.map(c => ({
      'Criado Em': c.createdAt,
      'Nome': c.nome_devedor,
      'Descrição': c.descriptor,
      'Status': c.status,
      'Valor': c.valor_total_cobranca,
      'Vencimento': c.vencimento,
      'E-mail': c.email1,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Cobranças");
    XLSX.writeFile(workbook, "cobrancas.xlsx");
  };

  return (
    <Box className="w-full">
      <Typography className="font-bold mb-2" sx={{ color: corPrincipalEscurecida, fontSize: '1.5rem' }}>
        <RequestQuoteIcon className="text-gray-700 m-2" sx={{ color: corPrincipalEscurecida, mb: '2px' }} />
        Todas Cobranças
      </Typography>
      <div className="mb-2" style={{ borderBottom: `3px solid ${corPrincipalEscurecida}` }} />
      <Box className={`flex flex-col ${isMobile ? 'gap-3' : 'md:flex-row md:items-center md:justify-between'} mb-2`}>
        <DateRangePicker
          onDateChange={(range) => {
            setDatefrom(range.datefrom);
            setDateto(range.dateto);
          }}
          dateTo={dateto}
          dateFrom={datefrom}
          setDateTo={setDateto}
          setDateFrom={setDatefrom}
          selection={selection}
          setSelection={setSelection}
        />

        <Box className={`flex ${isMobile ? 'flex-col gap-2' : 'flex-row gap-2 items-center'}`}>
          <TextField
            variant="outlined"
            placeholder="Buscar"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              height: 36,
              '& .MuiOutlinedInput-root': {
                height: 36,
                width: 180,
                fontSize: '0.875rem',
                backgroundColor: '#f7f7f7',
                borderRadius: '8px'
              }
            }}
          />

          <Button
            variant="contained"
            onClick={handleExport}
            sx={{ backgroundColor: corPrincipal, '&:hover': { backgroundColor: corPrincipalEscurecida } }}
          >
            Exportar (XLS)
          </Button>
        </Box>
      </Box>

      <Box sx={{ height: 'calc(100vh - 230px)', minHeight: 500, width: '100%', position: 'relative' }}>
        {isLoading ? (
          <Box className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <CircularLoaderWithCenterLogo />
          </Box>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25, 50, 100]}
            checkboxSelection
            disableRowSelectionOnClick
            rowHeight={40}
            columnHeaderHeight={40}
            onRowSelectionModelChange={(selection) => {
              const selecionados = cobrancasFiltradas
                .filter(row => selection.includes(row.hashInvoiceId))
                .map(row => row.hashInvoiceId);
              setCheckboxCobrancasSelecionadas(selecionados);
            }}
            sx={{
              backgroundColor: 'white',
              borderRadius: 2
            }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
      </Box>
    </Box>
  );
}
