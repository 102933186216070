import { Modal, Box, Typography, Button, CircularProgress } from "@mui/material";
import { getAcordoStatusButton } from "../../utils/statusButton";
import { getColorProfile } from "../../utils/appColors";
import { useState } from "react";

const MudaStatusButtonModal = ({ open, onClose, statuses, handleSelecionarStatus }) => {
    const { corPrincipalEscurecida, corPrincipal } = getColorProfile();
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleStatusClick = (status) => {
        setSelectedStatus(status);
    };

    const handleConfirmSelection = async () => {
        setIsLoading(true);
        if (selectedStatus) {
            await handleSelecionarStatus(selectedStatus);
            onClose();
        }
        setIsLoading(false);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                className="bg-white p-6 rounded-lg shadow-lg"
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    textAlign: "center",
                    overflow: "hidden",
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2" sx={{ color: corPrincipalEscurecida }}>
                    Selecione um novo status do Acordo
                </Typography>
                <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-5" />

                {statuses &&
                    statuses.map((status) => (
                        <div key={status} style={{ marginBottom: "15px" }}>
                            <div
                                onClick={() => handleStatusClick(status)}
                                style={{
                                    display: "inline-block",
                                    padding: "5px",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    transition: "all 0.3s ease-in-out",
                                    boxShadow: selectedStatus === status ? "0px 0px 10px rgba(0, 255, 0, 0.7)" : "none",
                                    border: selectedStatus === status ? `2px solid ${corPrincipalEscurecida}` : "none",
                                }}
                            >
                                {getAcordoStatusButton(status)}
                            </div>
                        </div>
                    ))}

                <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-5" />

                <div className="flex justify-end space-x-4 mt-5">
                    <Button
                        sx={{
                            background: "linear-gradient(to right, #f87171, #ef4444)",
                            color: "white",
                            borderRadius: "6px",
                            height: "35px",
                            mr: 1,
                        }}
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    {isLoading ? (
                        <CircularProgress sx={{ color: corPrincipal }} />
                    ) :
                        (<Button
                            sx={{
                                background: selectedStatus ? "linear-gradient(to right, #4caf50, #2e7d32)" : "gray",
                                color: "white",
                                borderRadius: "6px",
                                height: "35px",
                            }}
                            disabled={!selectedStatus}
                            onClick={handleConfirmSelection}
                        >
                            Selecionar
                        </Button>)}

                </div>
            </Box>
        </Modal >
    );
};

export { MudaStatusButtonModal };
