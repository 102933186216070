import axios from './axiosInstance';
import { getBearerTokenConfig } from './auth';

async function buscaExtrato(customerTaxId, datefrom = null, dateto = null) {
    const endpoint = localStorage.getItem('portalDomain') !== null ?
        localStorage.getItem('portalDomain') + '/api/v2' : process.env.REACT_APP_API_EZCALE;

    let buscaExtratoData = {
        customerTaxId,
        datefrom,
        dateto,
        action: 'accountstatement',
        tipoConta: 'Conta Smart'
    }
   
    if (datefrom == null) {
        buscaExtratoData = {
            ...buscaExtratoData,
            datefrom: '2023-01-01'
        }
    }
    if (dateto == null) {
        buscaExtratoData = {
            ...buscaExtratoData,
            dateto: '2030-10-15'
        }
    }

    try {
        const { data } = await axios.post(endpoint + "/statements.php",
            buscaExtratoData,
            getBearerTokenConfig());
            console.log('data', data);
        if (data.status_code === 200) {
            return data.data;
        }
        return {};
    }
    catch {
        return {};
    }
}

export {
    buscaExtrato
}