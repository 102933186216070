import React, { useEffect, useState, lazy, Suspense, useMemo } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Box
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Ícone de voltar
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ThemeProvider } from "@mui/material/styles";
import TaskIcon from '@mui/icons-material/Task';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import * as XLSX from 'xlsx';
import { getColorProfile } from '../../utils/appColors';
import { buscaDadosAcordoPorCarteira } from '../../api/backend-csi/acordo';
import { buscaCobrancas } from '../../api/ezcale/cobrancas';
import { getInvoiceStatusButton } from '../../utils/statusButton';
import CircularLoaderWithCenterLogo from '../../components/Loader/CircularLoaderWithCenterLogo';
import { buscaDetalhesDaCobranca } from './CobrancaService';
import SegundaViaModal from "./Modal/SegundaVia/SegundaViaModal";
import { formataDataHoraMySQLParaDataBrasileira } from '../../utils/dateFunctions';
import CobrancasMenu from "./CobrancasMenu";
import { buscaDadosCondominosNaoProcessados } from "../../api/backend-csi/importsjsonoutputs";
import theme from "../../utils/themeProvider";

const AcordoModal = lazy(() => import('./Modal/CriarAcordo/AcordoModal'));
const DarBaixarModal = lazy(() => import('./Modal/DarBaixa/DarBaixarModal'));
const EnviarPropostaDeAcordoModal = lazy(() => import('./Modal/EnviarPropostaDeAcordo/EnviarPropostaDeAcordoModal'));
const HistoricoModal = lazy(() => import('../../components/Modal/HistoricoModal'));
const ClientesNaoProcessadosModal = lazy(() => import('./Modal/ClientesNaoProcessadosModal/ClientesNaoProcessadosModal'));
const MoveCobrancasModal = lazy(() => import('./Modal/MoveCobrancasModal/MoveCobrancasModal'));
const EmitirCobrancaModal = lazy(() => import('./Modal/EmitirCobrancaModal/EmitirCobrancaModal'));
const RegistraProcessoModal = lazy(() => import('./Modal/RegistraProcessoModal/RegistraProcessoModal'));

export default function Cobrancas() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCobranca, setSelectedCobranca] = useState(null); // Variável para armazenar a cobrança selecionada
  const [acordoModalOpen, setAcordoModalOpen] = useState(false);
  const [segundaViaModalOpen, setSegundaViaModalOpen] = useState(false);
  const [darBaixaModalOpen, setDarBaixaModalOpen] = useState(false);
  const [emitirCobrancaOpen, setEmitirCobrancaOpen] = useState(false);
  const [moveCobrancaoOpen, setMoveCobrancaOpen] = useState(false);
  const [enviarPropostaDeAcordoModal, setEnviarPropostaDeAcordoModal] = useState(false);
  const [cobrancas, setCobrancas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');  // Estado para o termo de busca
  const [nomeCarteira, setNomeCarteira] = useState('');
  const [acordoDetalhes, setAcordoDetalhes] = useState({});
  const [checkboxCobrancasSelecionadas, setCheckBoxCobrancasSelecionadas] = useState([]);
  const [propostaEnviada, setPropostaEnviada] = useState(false);
  const [showEnviarPropostaDeAcordoModalButton, setShowEnviarPropostaDeAcordoModalButton] = useState(false);
  const [modalHistoricoOpen, setModalHistoricoOpen] = useState(false);
  const [clientesNaoProcessadosModalOpen, setClientesNaoProcessadosModalOpen] = useState(false);
  const [registraProcessoModalOpen, setRegistraProcessoModalOpen] = useState(false);
  const [clientesNaoProcessadosJSON, setClientesNaoProcessadosJSON] = useState({});

  const { hashId } = useParams(); // Pega o parâmetro 'hashId' da carteira
  const navigate = useNavigate(); // Hook para navegação 
  const { corPrincipal,
    corPrincipalEscurecida,
    corSecundaria,
    corSecundariaEscurecida,
    corPrincipalMaisClara } = getColorProfile();

  useEffect(() => {
    buscaCobrancasData();
  }, []);

  useEffect(() => {
    const checkTemAcordo = async () => {
      if (nomeCarteira.includes('Acordo')) {
        const acordoDetails = await buscaDadosAcordoPorCarteira(hashId);
        if (acordoDetails?.acordo?.id) {
          setShowEnviarPropostaDeAcordoModalButton(true);
          if (acordoDetails?.acordo?.data_envio != null) {
            setPropostaEnviada(true);
            setAcordoDetalhes(acordoDetails);
          }
        }
      }
    }

    const checkTemDadosImportacao = async () => {
      const condominosNaoProcessados = await buscaDadosCondominosNaoProcessados(hashId);
      setClientesNaoProcessadosJSON(condominosNaoProcessados);
    }
    checkTemAcordo();
    checkTemDadosImportacao();
  }, [nomeCarteira]);

  const cobrancasFiltradas = useMemo(() => {
    return cobrancas.filter(c =>
      c.nome_devedor?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      c.descriptor?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      c.email1?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [cobrancas, searchTerm]);

  const buscaCobrancasData = async () => {
    let cobrancas = [];
    setIsLoading(true);
    if (hashId !== 'all' && nomeCarteira == '') {

      cobrancas = await buscaCobrancas(hashId);
      //Pega o id da Carteira
      const nomeCarteira = cobrancas[0]?.invoiceName; // Mudança Temporaria para ver se não preciso do request abaixo para pegar o nome da Carteira
      if (nomeCarteira) {
        setNomeCarteira(nomeCarteira)
      }
    }

    setCobrancas(cobrancas);
    setIsLoading(false);
  }

  const handleDelete = (id) => {
    if (window.confirm("Tem certeza que deseja excluir esta cobrança?")) {
      console.log("Deletando cobrança com ID:", id);
    }
  };

  const handleDropdownClick = (event, cobranca) => {
    setSelectedCobranca(cobranca);

    const rect = event.currentTarget.getBoundingClientRect();
    setAnchorEl({
      top: rect.bottom + window.scrollY,  // Posição vertical
      left: rect.left + window.scrollX   // Posição horizontal
    });

    buscaDetalhesDaCobranca(cobranca.hashInvoiceId, setSelectedCobranca);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getValorFormatado = (valor) => {
    return "R$ " + valor.replace('.', ',');
  }

  const getVencimentoFormatado = (vencimento) => {
    const [date] = vencimento.split(" ");
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  const handleHistoricoModalClose = () => {
    setModalHistoricoOpen(false);
  }

  const handleExport = () => {
    const exportData = cobrancasFiltradas.map(c => ({
      'Criado Em': c.createdAt,
      'Nome': c.nome_devedor,
      'Descrição': c.descriptor,
      'Status': c.status,
      'Valor': c.valor_total_cobranca,
      'Vencimento': c.vencimento,
      'E-mail': c.email1,
      'Telefone': c.telefone1,
      'Nome da Carteira': c.invoiceName,
      'Id da Cobrança': c.hashInvoiceId,
      'Id da Carteira': c.hashInvoiceGroupId,
      'Cpf/Cnpj Credor': c.cpf_cnpj_credor
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Cobranças');
    XLSX.writeFile(workbook, 'cobrancas.xlsx');
  };

  return (
    isLoading ? (<div className="w-full h-full flex items-center justify-center">
      <CircularLoaderWithCenterLogo />
    </div>) : (
      <ThemeProvider theme={theme}>
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-center items-center" >
            <div className="w-full flex flex-col sm:flex-row sm:items-center sm:justify-start gap-2 sm:gap-4 mb-[-2px]">
              <div className="flex items-center">
                <RequestQuoteIcon
                  sx={{
                    color: corPrincipalEscurecida,
                    mr: '4px'
                  }}
                />
                <Typography
                  className="font-bold"
                  sx={{
                    color: corPrincipalEscurecida,
                    fontSize: '1.5rem',
                    lineHeight: 1.2,
                    display: 'inline-flex',
                    alignItems: 'center'
                  }}
                >
                  Cobranças

                  {Object.keys(clientesNaoProcessadosJSON).length > 0 && (
                    <ErrorOutlineIcon
                      sx={{ color: 'red', paddingBottom: '4px', paddingLeft: '2px' }}
                      onClick={() => setClientesNaoProcessadosModalOpen(true)}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </Typography>
              </div>

              <Typography
                className="font-bold"
                sx={{
                  color: corPrincipal,
                  fontSize: '1.3rem',
                  marginLeft: { sm: '10px', xs: 0 },
                  lineHeight: 1.2
                }}
              >
                Carteira - {nomeCarteira}
              </Typography>
            </div>

          </div>
          <div className="w-full bg-white p-1 shadow-lg">
            {/* Separador acima */}
            <div className="mb-2" style={{ borderBottom: `3px solid ${corPrincipalEscurecida}` }} />


            <Box className="flex flex-col md:flex-row md:justify-between items-center w-full md:space-y-1">
              <Button
                className="flex-shrink-0 md:mb-0 mt-2"
                onClick={() => navigate(-1)}
                sx={{
                  color: corPrincipalEscurecida,
                  borderColor: corPrincipalEscurecida,
                  height: '30px',
                  fontSize: '0.75rem'
                }}
                variant="outlined"
                startIcon={<ArrowBackIcon />}>Voltar</Button>

              <div className="flex flex-col md:flex-row md:justify-evenly space-y-2 md:space-x-2 md:space-y-0">
                {
                  showEnviarPropostaDeAcordoModalButton && !propostaEnviada ? (
                    <Button
                      className="flex-shrink-0 md:mb-0 mt-2"
                      onClick={() => setEnviarPropostaDeAcordoModal(true)}
                      sx={{
                        color: corPrincipalEscurecida,
                        borderColor: corPrincipalEscurecida,
                        height: '30px'
                      }}
                      variant="outlined"
                      startIcon={<TaskIcon />}>Enviar Proposta de Acordo</Button>
                  ) : showEnviarPropostaDeAcordoModalButton && propostaEnviada && (
                    <Button

                      className="flex-shrink-0 md:mb-0 mt-2"
                      onClick={() => setEnviarPropostaDeAcordoModal(true)}
                      sx={{
                        color: corPrincipalEscurecida,
                        borderColor: corPrincipalEscurecida,
                        height: '30px'
                      }}
                      variant="outlined"
                      startIcon={<TaskIcon />}>Proposta de Acordo Enviada ({formataDataHoraMySQLParaDataBrasileira(acordoDetalhes?.acordo?.data_envio, 3)}) </Button>
                  )
                }
                <Button
                  disabled={checkboxCobrancasSelecionadas.length === 0}
                  className="justify-self-end"
                  sx={{
                    color: corPrincipalEscurecida,
                    borderColor: corPrincipalEscurecida,
                    borderWidth: "2px",
                    fontWeight: "bold",
                    marginLeft: '10px',
                    height: '30px'
                  }}
                  variant="outlined"
                  onClick={() => setEmitirCobrancaOpen(true)}
                >
                  Emitir Cobrança(s)
                </Button>
                <Button
                  disabled={checkboxCobrancasSelecionadas.length === 0}
                  className="justify-self-end"
                  sx={{
                    color: corPrincipalEscurecida,
                    borderColor: corPrincipalEscurecida,
                    borderWidth: "2px",
                    fontWeight: "bold",
                    marginLeft: '10px',
                    height: '30px'
                  }}
                  variant="outlined"
                  onClick={() => setMoveCobrancaOpen(true)}
                >
                  Mover Cobrança(s)
                </Button>
              </div>
              <Box className="flex items-end mt-4">
                <TextField
                  variant="outlined"
                  placeholder="Buscar"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '30px',
                      width: '200px',
                      backgroundColor: '#f7f7f7',
                      borderRadius: '8px',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: corPrincipalEscurecida,
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: corPrincipal,
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: corPrincipal,
                    },
                  }}
                />
                <Button
                  variant="contained"
                  onClick={handleExport}
                  sx={{
                    backgroundColor: corPrincipal,
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '0.75rem',
                    height: '30px',
                    marginLeft: '4px', // Espaço mínimo entre TextField e Botão
                    '&:hover': {
                      backgroundColor: corPrincipalEscurecida
                    }
                  }}
                >
                  Exportar (XLS)
                </Button>
              </Box>

              {/* Campo de busca estilizado */}


              {/* Botão de Configuração com ícone
              <IconButton className="ml-4 hover:bg-gray-200">
                <SettingsIcon style={{ fontSize: 30, color: '#616161' }} />
              </IconButton> */}
            </Box>
          </div>
          {/* Mobile version: Display as Cards */}
          <div className="lg:hidden w-full space-y-3 mt-4" >
            {
              cobrancas.map((cobranca, index) => {
                const backgroundColor = index % 2 === 0 ? '#FFF9F5' : '#F5F9FF';
                return (<Card
                  key={cobranca.id}
                  sx={{
                    backgroundColor,
                    width: "100%",
                    boxShadow: 3,
                    borderRadius: 6,
                  }}
                >
                  <CardContent>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-4">
                        <Typography variant="h6" component="div" sx={{ color: corPrincipalEscurecida }}>
                          {cobranca.nome_devedor}
                        </Typography>
                      </div>
                      <div className="flex space-x-2">
                        <Button
                          variant="contained"
                          onClick={(event) => handleDropdownClick(event, cobranca)}
                          sx={{
                            background: `linear-gradient(to right, ${corSecundaria}, ${corSecundariaEscurecida})`,
                            borderRadius: "6px",
                            color: "white",
                            height: "30px",
                            fontSize: "0.65rem",
                          }}
                        >
                          Ações
                        </Button>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Typography color="textSecondary"><b>Valor:</b> {getValorFormatado(cobranca.valor_total_cobranca)}</Typography>
                      <Typography variant="body2"><b>Vencimento:</b> {getVencimentoFormatado(cobranca.vencimento)}</Typography>
                      <Typography variant="body2"><b>E-mail:</b> {cobranca.email1}</Typography>
                      <Typography variant="body2"><b>Celular: </b>{cobranca.telefone1}</Typography>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="flex justify-start">
                        {getInvoiceStatusButton(cobranca.status)}
                      </div>
                    </div>
                  </CardContent>
                </Card>
                )
              })
            }
          </div>
          {/* Desktop version: Display as Table */}
          <Box className="hidden lg:block w-full mt-4" sx={{ height: 'calc(100vh - 230px)', minHeight: 500 }}>
            {isLoading ? (
              <Box className="w-full h-full flex items-center justify-center">
                <CircularLoaderWithCenterLogo />
              </Box>
            ) : (
              // (continuação da tabela DataGrid com ajustes visuais e colunas ocultas)
              <DataGrid
                key={cobrancasFiltradas.length}
                rows={cobrancasFiltradas
                  .filter(c => !!c.hashInvoiceId)
                  .map(c => ({ id: c.hashInvoiceId, ...c }))}
                columns={[
                  {
                    field: 'createdAt',
                    headerName: 'Criado Em',
                    flex: 1,
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (params) => {
                      if (!params.value) return '';
                      const [date] = params.value.split(' ');
                      const [y, m, d] = date.split('-');
                      return `${d}/${m}/${y}`;
                    }
                  },
                  {
                    field: 'nome_devedor',
                    headerName: 'Nome',
                    flex: 2,
                    align: 'center',
                    headerAlign: 'center'
                  },
                  {
                    field: 'descriptor',
                    headerName: 'Descrição',
                    flex: 2,
                    align: 'center',
                    headerAlign: 'center'
                  },
                  {
                    field: 'status',
                    headerName: 'Status',
                    flex: 1,
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (params) => getInvoiceStatusButton(params.value)
                  },
                  {
                    field: 'valor_total_cobranca',
                    headerName: 'Valor',
                    flex: 1,
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (params) => `R$ ${params.value?.replace('.', ',')}`
                  },
                  {
                    field: 'vencimento',
                    headerName: 'Vencimento',
                    flex: 1,
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (params) => {
                      if (!params.value) return '';
                      const [date] = params.value.split(' ');
                      const [y, m, d] = date.split('-');
                      return `${d}/${m}/${y}`;
                    }
                  },
                  {
                    field: 'email1',
                    headerName: 'E-mail',
                    flex: 2,
                    align: 'center',
                    headerAlign: 'center'
                  },
                  // Colunas adicionais ocultas
                  { field: 'telefone1', headerName: 'Telefone', flex: 1.2, align: 'center', headerAlign: 'center', hide: true },
                  { field: 'invoiceName', headerName: 'Nome da Carteira', flex: 2, align: 'center', headerAlign: 'center', hide: true },
                  { field: 'hashInvoiceId', headerName: 'Id da Cobrança', flex: 1.2, align: 'center', headerAlign: 'center', hide: true },
                  { field: 'hashInvoiceGroupId', headerName: 'Id da Carteira', flex: 1, align: 'center', headerAlign: 'center', hide: true },
                  { field: 'cpf_cnpj_credor', headerName: 'Cpf/Cnpj Credor', flex: 1.2, align: 'center', headerAlign: 'center', hide: true },
                  {
                    field: 'acoes',
                    headerName: 'Ações',
                    flex: 1,
                    align: 'center',
                    headerAlign: 'center',
                    sortable: false,
                    filterable: false,
                    renderCell: (params) => (
                      <Button
                        variant="contained"
                        onClick={(event) => handleDropdownClick(event, params.row)}
                        sx={{
                          background: `linear-gradient(to right, ${corSecundaria}, ${corSecundariaEscurecida})`,
                          borderRadius: "6px",
                          color: "white",
                          height: "30px",
                          fontSize: "0.65rem",
                        }}
                      >
                        Ações
                      </Button>
                    )
                  }
                ]}
                pageSize={25}
                rowsPerPageOptions={[25, 50, 100]}
                checkboxSelection
                onRowSelectionModelChange={(newSelection) => {
                  setCheckBoxCobrancasSelecionadas(newSelection);
                }}
                disableRowSelectionOnClick
                rowHeight={40}
                columnHeaderHeight={40}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'linha-par' : 'linha-impar'
                }
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 2,
                  '& .MuiDataGrid-columnHeaders': {
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    color: 'black',
                    backgroundColor: '#f5f5f5'
                  },
                  '& .linha-par': {
                    backgroundColor: 'white'
                  },
                  '& .linha-impar': {
                    backgroundColor: corPrincipalMaisClara
                  }
                }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      telefone1: false,
                      invoiceName: false,
                      hashInvoiceId: false,
                      hashInvoiceGroupId: false,
                      cpf_cnpj_credor: false,
                    }
                  }
                }}
              />

            )}
          </Box>
        </div >

        {/* Modais */}
        {selectedCobranca?.hashInvoiceId && (
          <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
            <CircularLoaderWithCenterLogo />
          </div>}>
            <AcordoModal
              open={acordoModalOpen}
              handleClose={() => setAcordoModalOpen(false)
              }
              cobranca={selectedCobranca}
            />
          </Suspense>
        )}

        {selectedCobranca?.hashInvoiceId && (
          <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
            <CircularLoaderWithCenterLogo />
          </div>}>
            <SegundaViaModal
              open={segundaViaModalOpen}
              handleClose={() => setSegundaViaModalOpen(false)
              }
              cobranca={selectedCobranca}
            />
          </Suspense>
        )}

        {hashId && enviarPropostaDeAcordoModal && (
          <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
            <CircularLoaderWithCenterLogo />
          </div>}>
            <EnviarPropostaDeAcordoModal
              open={enviarPropostaDeAcordoModal}
              handleClose={() => setEnviarPropostaDeAcordoModal(false)}
              hashCarteiraId={hashId}
              cobrancas={cobrancas}
              setAcordoDetalhes={setAcordoDetalhes}
            />
          </Suspense>
        )}

        {selectedCobranca?.hashInvoiceId && (
          <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
            <CircularLoaderWithCenterLogo />
          </div>}>
            <DarBaixarModal
              open={darBaixaModalOpen}
              handleClose={() => setDarBaixaModalOpen(false)
              }
              cobranca={selectedCobranca}
            />
          </Suspense>
        )}

        {checkboxCobrancasSelecionadas.length > 0 && (
          <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
            <CircularLoaderWithCenterLogo />
          </div>}>
            <EmitirCobrancaModal
              open={emitirCobrancaOpen}
              onClose={() => setEmitirCobrancaOpen(false)
              }
              cobrancasSelecionadasParaEmissao={checkboxCobrancasSelecionadas}
            />
          </Suspense>
        )}
        {checkboxCobrancasSelecionadas.length > 0 && (
          <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
            <CircularLoaderWithCenterLogo />
          </div>}>
            <MoveCobrancasModal
              open={moveCobrancaoOpen}
              onClose={() => setMoveCobrancaOpen(false)
              }
              cobrancasSelecionadasParaEmissao={checkboxCobrancasSelecionadas}
              nomeCarteiraAtual={nomeCarteira}
            />
          </Suspense>
        )}
        {anchorEl !== null && (<CobrancasMenu
          anchorEl={anchorEl}
          anchorPosition={{ top: anchorEl.top, left: anchorEl.left }}
          handleClose={handleClose}
          setAcordoModalOpen={setAcordoModalOpen}
          setSegundaViaModalOpen={setSegundaViaModalOpen}
          setDarBaixaModalOpen={setDarBaixaModalOpen}
          selectedCobranca={selectedCobranca}
          setIsLoading={setIsLoading}
          setModalHistoricoOpen={setModalHistoricoOpen}
          setRegistraProcessoModalOpen={setRegistraProcessoModalOpen}
        />)}
        <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
          <CircularLoaderWithCenterLogo />
        </div>}>
          <HistoricoModal
            open={modalHistoricoOpen}
            onClose={handleHistoricoModalClose}
            hashInvoiceId={selectedCobranca?.hashInvoiceId} />
        </Suspense>
        <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
          <CircularLoaderWithCenterLogo />
        </div>}>
          <ClientesNaoProcessadosModal
            open={clientesNaoProcessadosModalOpen}
            onClose={() => setClientesNaoProcessadosModalOpen(false)}
            clientesNaoProcessados={clientesNaoProcessadosJSON}
          />
        </Suspense>

        <Suspense fallback={<div className="w-full h-full flex items-center justify-center">
          <CircularLoaderWithCenterLogo />
        </div>}>
          <RegistraProcessoModal
            open={registraProcessoModalOpen}
            onClose={() => setRegistraProcessoModalOpen(false)}
            cobranca={selectedCobranca}
          />
        </Suspense>
      </ThemeProvider >)
  );
}
