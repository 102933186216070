import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    Box,
    Card,
    CardContent,
    Button
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DvrIcon from '@mui/icons-material/Dvr';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { buscaExtrato } from '../../api/ezcale/extrato';
import { listaCustomers } from '../../api/ezcale/customer';
import { getColorProfile } from "../../utils/appColors";
import DateRangePicker from "../../utils/DateRangePicker";
import { formatDate, subtraiXDiasDeHoje } from "../../utils/dateFunctions";
import CircularLoaderWithCenterLogo from '../../components/Loader/CircularLoaderWithCenterLogo';

const Extrato = () => {
    const { corPrincipal, corPrincipalEscurecida, corSecundariaMaisClara, corSecundaria } = getColorProfile();
    const [extrato, setExtrato] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dateto, setDateto] = useState(formatDate(new Date()));
    const [datefrom, setDatefrom] = useState(subtraiXDiasDeHoje(7));
    const [dateIntervalSelection, setDateIntervalSelection] = useState('7 Dias');
    const [customerTaxId, setCustomerTaxId] = useState("");
    const [customers, setCustomers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [extratoCompleto, setExtratoCompleto] = useState([]); //Utilizado para armazenar todos os dados para utilizar o filtro de busca

    const theme = createTheme({
        typography: {
            fontFamily: "'Roboto', sans-serif",
        },
    });

    // Função para formatar valores monetários
    const formatCurrency = (value) => {
        return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        }).format(parseFloat(value));
    };

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await listaCustomers();
                setCustomers(response);
                if (response.length > 0) {
                    setCustomerTaxId(response[0].customerTaxId);
                }
            } catch (error) {
                console.error("Erro ao buscar customers", error);
            }
        };
        fetchCustomers();
    }, []);

    useEffect(() => {
        if (!customerTaxId) return;

        const fetchExtrato = async () => {
            setLoading(true);
            try {
                const response = await buscaExtrato(customerTaxId, datefrom, dateto);
                setExtrato(response.entries);
                setExtratoCompleto(response.entries);
            } catch (error) {
                console.error("Erro ao buscar extrato", error);
            } finally {
                setLoading(false);
            }
        };

        fetchExtrato();
    }, [customerTaxId, datefrom, dateto]);

    const handleDateChange = (range) => {
        setDatefrom(range.datefrom);
        setDateto(range.dateto);
    };

    // Função de filtro
    const handleSearch = (e) => {
        const term = e.target.value !== '' ? e.target.value.toLowerCase() : '';
        if (term != searchTerm) {
            setSearchTerm(term);
            const extratoDuplicado = [...extratoCompleto];
            const extratoFiltrado = extratoDuplicado.filter((item) =>
                item.description?.toLowerCase().includes(term)
            );

            setExtrato(extratoFiltrado);
        }
    };

    const openContaDigitalSistemaLegado = () => {
        try {
            const token = localStorage.getItem('user_token');
            if (!token) {
                alert("Token não encontrado.");
                return;
            }

            const payloadBase64 = token.split('.')[1];
            const payloadJson = atob(payloadBase64);
            const payload = JSON.parse(payloadJson);

            const sessionToken = payload.sessionTokenForLogin;
            if (!sessionToken) {
                alert("sessionTokenForLogin não encontrado no token.");
                return;
            }

            // ==== TRATAMENTO DE DOMÍNIO AVANÇADO ====
            const hostname = window.location.hostname;

            // Lista de domínios de segundo nível comuns
            const knownSecondLevelDomains = [
                'com.br', 'net.br', 'gov.br', 'org.br',
                'com.ar', 'com.co', 'co.uk', 'org.uk'
            ];

            const hostParts = hostname.split('.');
            let baseDomain;

            // Junta os dois últimos pedaços
            const lastTwo = hostParts.slice(-2).join('.');
            const lastThree = hostParts.slice(-3).join('.');

            if (knownSecondLevelDomains.includes(lastTwo)) {
                // Se o final for tipo com.br, pega os últimos 3
                baseDomain = lastThree;
            } else {
                // Se for domínio comum (.com, .net, .xyz), pega os últimos 2
                baseDomain = lastTwo;
            }

            const loginDomain = `login.${baseDomain}`;
            const url = `https://${loginDomain}/myaccountcp.php?sessionStart=${encodeURIComponent(sessionToken)}`;

            window.open(url, "_blank");
        } catch (error) {
            console.error("Erro ao processar o token:", error);
            alert("Ocorreu um erro ao processar o token.");
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="p-4">
                {/* Cabeçalho com Select de Clientes */}
                <Box className="flex justify-between items-center w-full flex-col sm:flex-row">
                    {/* Título e Ícone */}
                    <Typography variant="h4" component="h4" sx={{ color: corPrincipalEscurecida }}>
                        <ReceiptIcon className="text-gray-700 m-2" sx={{ color: corPrincipalEscurecida, mb: '5px' }} />
                        Extrato
                    </Typography>
                    <Box className="flex justify-between flex-col">
                        <Button
                            className="flex-shrink-0 m-8"
                            onClick={() => openContaDigitalSistemaLegado()}
                            sx={{
                                position: "relative",
                                top: "0",
                                right: "0",
                                color: corPrincipalEscurecida,
                                borderColor: corPrincipalEscurecida,
                                mb: "10px",
                                zIndex: 1,
                                '@media (max-width: 600px)': {
                                    top: "0",
                                    right: "0",
                                    fontSize: "0.75rem",
                                    padding: "4px 8px",
                                },
                            }}
                            variant="outlined"
                            startIcon={<DvrIcon />}
                        >
                            Visualizar Conta Digital
                        </Button>
                        <FormControl
                            variant="outlined"
                            sx={{
                                minWidth: 250,
                                width: "100%",
                                maxWidth: 350,
                                marginBottom: 1,
                                mt: { xs: 2, sm: 0 },
                                "& .MuiOutlinedInput-root": {
                                    height: "40px",
                                    // border: `2px solid ${corPrincipalEscurecida}`,
                                    // borderRadius: "8px",
                                    // "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: corPrincipalEscurecida },
                                    // "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: corPrincipal },
                                },
                            }}
                        >
                            <InputLabel id="simple-select-label">Cliente</InputLabel>
                            <Select
                                label="Cliente"
                                value={customerTaxId}
                                onChange={(e) => setCustomerTaxId(e.target.value)}
                                slotProps={{ inputLabel: { shrink: true } }}
                            >
                                {customers.map((customer) => (
                                    <MenuItem key={customer.customerTaxId} value={customer.customerTaxId}>
                                        {customer.statement_descriptor || customer.customerName} ({customer.customerTaxId})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {/* Linha divisória */}
                <div className="mb-2" style={{ borderBottom: `3px solid ${corPrincipalEscurecida}` }} />

                {/* Filtros (Data e Pesquisa) */}
                <div className="flex justify-between items-center w-full py-2 space-x-4 flex-col sm:flex-row">
                    <DateRangePicker
                        onDateChange={(range) => handleDateChange(range)}
                        dateTo={dateto}
                        dateFrom={datefrom}
                        setDateTo={setDateto}
                        setDateFrom={setDatefrom}
                        selection={dateIntervalSelection}
                        setSelection={setDateIntervalSelection}
                    />

                    {/* Campo de pesquisa */}
                    <TextField
                        variant="outlined"
                        placeholder="Procurar"
                        value={searchTerm}
                        onChange={handleSearch}
                        sx={{
                            width: "100%",
                            maxWidth: 300,
                            mt: { xs: 2, sm: 0 },
                            "& .MuiOutlinedInput-root": {
                                height: "40px",
                                backgroundColor: "#f7f7f7",
                                borderRadius: "8px",
                                "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: corPrincipalEscurecida },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: corPrincipal },
                            },
                            "& .MuiOutlinedInput-notchedOutline": { borderColor: corSecundaria },
                        }}
                    />
                </div>

                {/* Loader */}
                {loading && (
                    <div className="w-full h-full flex items-center justify-center">
                        <CircularLoaderWithCenterLogo />
                    </div>
                )}

                {/* 📌 Versão Mobile - Exibição como Cards */}
                {!loading && (
                    <div className="lg:hidden w-full space-y-3 mt-4">
                        {extrato?.map((entry, index) => {
                            const backgroundColor = index % 2 === 0 ? "#FFF9F5" : "#F5F9FF";
                            return (
                                <Card key={index} style={{ backgroundColor, width: "100%", boxShadow: 3, borderRadius: "16px" }}>
                                    <CardContent>
                                        <Typography variant="body2" className="text-gray-500">{entry.date}</Typography>
                                        <Typography variant="body1" className="font-semibold">{entry.description}</Typography>
                                        <Typography
                                            variant="h6"
                                            className={`font-bold ${entry.amount.startsWith("-") ? "text-red-500" : "text-green-600"}`}
                                        >
                                            {formatCurrency(entry.amount)}
                                        </Typography>
                                        <Typography variant="body2" className="text-gray-600">Saldo: {formatCurrency(entry.balance)}</Typography>
                                    </CardContent>
                                </Card>
                            );
                        })}
                    </div>
                )}

                {/* 📌 Versão Desktop - Exibição como Tabela */}
                {!loading && (
                    <TableContainer component={Paper} className="hidden lg:block w-[90%] shadow-lg mt-4 mx-auto">
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: corPrincipal, color: "white" }}>
                                    <TableCell align="center"><b>Data</b></TableCell>
                                    <TableCell align="left"><b>Descrição</b></TableCell>
                                    <TableCell align="right"><b>Valor</b></TableCell>
                                    <TableCell align="right"><b>Saldo</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {extrato?.map((entry, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">{entry.date}</TableCell>
                                        <TableCell align="left">{entry.description}</TableCell>
                                        <TableCell align="right" sx={{ color: entry.amount.startsWith("-") ? "red" : "green" }}>
                                            {formatCurrency(entry.amount)}
                                        </TableCell>
                                        <TableCell align="right">{formatCurrency(entry.balance)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </ThemeProvider>
    );
};

export default Extrato;
