import React, { useState, useEffect } from 'react';
import { TextField, Button, Stack, Menu, MenuItem, IconButton, Modal, Box, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { getColorProfile } from './appColors';

export default function DateRangePicker({
  onDateChange,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  selection,
  setSelection,
}) {
  const { corPrincipal, corPrincipalEscurecida } = getColorProfile();
  const isMobile = useMediaQuery('(max-width:1023px)'); // Detecta tela pequena

  // Estados para controle temporário das datas no modal
  const [tempDateFrom, setTempDateFrom] = useState(dateFrom);
  const [tempDateTo, setTempDateTo] = useState(dateTo);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // Estado para abrir o menu no mobile

  // Helper para calcular uma data de X dias atrás
  function getDateXDaysAgo(days) {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().split('T')[0];
  }

  useEffect(() => {
    onDateChange({ datefrom: dateFrom, dateto: dateTo });
  }, [dateFrom, dateTo, onDateChange]);

  const handleDateRangeSelection = (range) => {
    let fromDate, toDate;
    switch (range) {
      case 'Hoje':
        fromDate = toDate = new Date().toISOString().split('T')[0];
        break;
      case '7 Dias':
        fromDate = getDateXDaysAgo(7);
        toDate = new Date().toISOString().split('T')[0];
        break;
      case '30 Dias':
        fromDate = getDateXDaysAgo(30);
        toDate = new Date().toISOString().split('T')[0];
        break;
      case '60 Dias':
        fromDate = getDateXDaysAgo(60);
        toDate = new Date().toISOString().split('T')[0];
        break;
      case '90 Dias':
        fromDate = getDateXDaysAgo(90);
        toDate = new Date().toISOString().split('T')[0];
        break;
      case 'Esse Mês':
        const today = new Date();
        fromDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
        toDate = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];
        break;
      case 'Esse Ano':
        const currentYear = new Date().getFullYear();
        fromDate = new Date(currentYear, 0, 1).toISOString().split('T')[0];
        toDate = new Date(currentYear, 11, 31).toISOString().split('T')[0];
        break;
      case 'Personalizar':
        setTempDateFrom(dateFrom);
        setTempDateTo(dateTo);
        setOpenModal(true); // Abre o modal para seleção personalizada
        return;
      default:
        return;
    }
    setDateFrom(fromDate);
    setDateTo(toDate);
    setSelection(range);
    handleCloseMenu();
  };

  // Abre e fecha o menu para mobile
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  // Funções para abrir e fechar o modal
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Confirma a seleção das datas no modal
  const handleConfirmCustomDates = () => {
    setDateFrom(tempDateFrom);
    setDateTo(tempDateTo);
    setSelection('Personalizar');
    handleCloseModal();
  };

  return (
    <Stack spacing={2} direction="column">
      {isMobile ? (
        <div className="flex justify-center">
          <IconButton onClick={handleOpenMenu} style={{ color: corPrincipalEscurecida }}>
            <FilterListIcon />
            <span style={{ fontSize: '1rem', marginLeft: '4px' }}>DATAS</span>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {['Hoje', '7 Dias', '30 Dias', '60 Dias', '90 Dias', 'Esse Mês', 'Esse Ano', 'Personalizar'].map((range) => (
              <MenuItem key={range} onClick={() => handleDateRangeSelection(range)}>
                {range}
              </MenuItem>
            ))}
          </Menu>
        </div>
      ) : (
        <div className="flex flex-wrap gap-1">
          {['Hoje', '7 Dias', '30 Dias', '60 Dias', '90 Dias', 'Esse Mês', 'Esse Ano', 'Personalizar'].map((range) => (
            <Button
              key={range}
              variant={selection === range ? 'contained' : 'outlined'}
              onClick={() => handleDateRangeSelection(range)}
              style={{
                width: 'fit-content',
                maxWidth: '110px',
                fontSize: '0.66rem',
                height: '21px',
                backgroundColor: selection === range ? corPrincipalEscurecida : 'transparent',
                color: selection === range ? '#fff' : corPrincipalEscurecida,
                borderColor: corPrincipalEscurecida,
              }}
            >
              {range}
            </Button>
          ))}
        </div>
      )}

      {/* Modal de seleção personalizada de datas */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 2,
            boxShadow: 24,
            width: '90%',
            maxWidth: 400,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Selecione o intervalo de datas
          </Typography>
          <TextField
            label="Data De"
            type="date"
            value={tempDateFrom}
            onChange={(e) => setTempDateFrom(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Data Até"
            type="date"
            value={tempDateTo}
            onChange={(e) => setTempDateTo(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              sx={{ color: corPrincipal, borderColor: corPrincipal, '&:hover': { borderColor: corPrincipalEscurecida } }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleConfirmCustomDates}
              style={{ backgroundColor: corPrincipalEscurecida, color: '#fff' }}>
              Confirmar
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
}
