import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  Typography,
  TextField,
  Button,
  Fade,
  CircularProgress,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SavingsIcon from "@mui/icons-material/Savings";
import PaidIcon from "@mui/icons-material/Paid";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { keyframes } from "@mui/system";
import { atualizaAcordo } from "../../api/backend-csi/acordo";
import { getColorProfile } from "../../utils/appColors";
import { atualizaStatusAcordo } from './DetalhesAcordoService';

const FALL_ANIMATION = keyframes`
  0% { transform: translateY(-100px) rotate(0deg); opacity: 0.9; }
  50% { opacity: 0.7; }
  75% { opacity: 0.4; }
  100% { transform: translateY(500px) rotate(360deg); opacity: 0; }
`;

const ICON_SIZE = 35; // Ajuste o tamanho dos ícones aqui

const AprovacaoModal = ({ open, onClose, codigoValidacao, acordo, condominio, codigoSentAt, abreModalDeAprovacao }) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);


  const { corPrincipalEscurecida, corSecundaria, corSecundariaEscurecida } = getColorProfile();

  const handleSubmit = () => {
    setError(false);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (inputValue == codigoValidacao) { 
        atualizaStatusAcordo('Aprovado', setLoading, acordo, condominio);
        setVerified(true);
      } else {
        setError(true);
      }
    }, 1000);
  };

  const FallingIcon = ({ IconComponent, delay }) => (
    <Box
      sx={{
        position: "absolute",
        top: "-150px",
        left: `${Math.random() * 90}%`,
        animation: `${FALL_ANIMATION} 3.5s ${delay}s linear infinite`,
        color: "gold",
      }}
    >
      <IconComponent sx={{ fontSize: ICON_SIZE }} />
    </Box>
  );

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const [timeLeft, setTimeLeft] = useState(0);
  const ajusteRegressiva = 10500000; // Ajuste o tempo do relógio aqui (o backend controla o tempo de expiração e o reenvio do código)

  useEffect(() => {
    const initialTimeLeft = Math.max(0, Math.floor((codigoSentAt - ajusteRegressiva - Date.now()) / 1000));
    setTimeLeft(initialTimeLeft);
    if (initialTimeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft(prev => Math.max(0, prev - 1));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [codigoSentAt]);

    return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" sx={{ color: corPrincipalEscurecida }}>
          <b>Aprovar Acordo</b>
        </Typography>
        <div style={{ borderBottom: `2px solid ${corPrincipalEscurecida}` }} className="mb-5" />
        {verified ? (
          <Fade in={verified}>
            <Box sx={{ position: "relative", height: "300px" }}>
              {[...Array(50)].map((_, i) => (
                <FallingIcon
                  key={`icon-${i}`}
                  IconComponent={
                    [
                      MonetizationOnIcon,
                      AttachMoneyIcon,
                      SavingsIcon,
                      PaidIcon,
                      AccountBalanceWalletIcon,
                    ][Math.floor(Math.random() * 5)]
                  }
                  delay={Math.random() * 4}
                />
              ))}
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              >
                <CheckCircleOutlineIcon
                  sx={{ color: "green", fontSize: 64, mb: 2 }}
                />
                <Typography variant="h6" sx={{ color: corPrincipalEscurecida }}>
                  Parabéns, Acordo Aprovado!
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 3,
                  position: "absolute",
                  bottom: 20,
                  left: "50%",
                  transform: "translateX(-50%)",
                  background: `linear-gradient(to right, ${corSecundaria}, ${corSecundariaEscurecida})`,
                }}
                onClick={() => window.location.reload()}
              >
                Finalizar
              </Button>
            </Box>
          </Fade>
        ) : (
          <Box>
            <Typography variant="subtitle1" gutterBottom sx={{ color: corPrincipalEscurecida }}>
              Enviamos um código para seu e-mail, insira o código no local abaixo
              para Aprovar o Acordo
            </Typography>
            <TextField
              error={error}
              helperText={error ? "Código inválido" : ""}
              variant="outlined"
              fullWidth
              placeholder="Digite o código"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              sx={{
                mt: 2,
                animation: error ? "shake 0.3s" : "none",
                "@keyframes shake": {
                  "0%": { transform: "translateX(0)" },
                  "25%": { transform: "translateX(-5px)" },
                  "50%": { transform: "translateX(5px)" },
                  "75%": { transform: "translateX(-5px)" },
                  "100%": { transform: "translateX(0)" },
                },
              }}
            />
            <Typography variant="body2" sx={{ mt: 1, color: timeLeft > 0 ? "green" : "red" }}>
              {timeLeft > 0
                  ? `Código válido por: ${formatTime(timeLeft)}`
                  : "Código expirado"}
            </Typography>
            {timeLeft > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{
                  mt: 3,
                  width: "100%",
                  background: `linear-gradient(to right, ${corSecundaria}, ${corSecundariaEscurecida})`,
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} sx={{ color: '#FFFFFF' }} /> : "Validar Código"}
              </Button>
            )}
            {timeLeft === 0 && (
              <Button
                variant="contained"
                color="secondary"
                onClick={async () => {
                  setLoading(true);
                  await abreModalDeAprovacao();
                  setLoading(false);
                }}
                sx={{
                  mt: 3,
                  width: "100%",
                  background: `linear-gradient(to right, ${corSecundaria}, ${corSecundariaEscurecida})`,
                }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} sx={{ color: '#FFFFFF' }} /> : "Reenviar Código"}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AprovacaoModal;