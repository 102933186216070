
import { createTheme } from "@mui/material/styles";

 const theme = createTheme({
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: "40px", // Altura mínima
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "4px", // Reduz o padding interno das células
            fontSize: "12px", // Define o tamanho da fonte menor
          },
        },
      },
    },
  });


  export default theme;